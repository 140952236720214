var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex d-flex-r asset-preview-wrapper"},[_c('div',{staticClass:"d-flex d-flex-c"},[_c('object',{staticClass:"selected-asset mb-1"},[(_vm.selectedAsset && _vm.selectedAssetPreview)?_c('img',{attrs:{"src":_vm.selectedAssetPreview},on:{"error":_vm.defaultBg}}):_vm._e(),(_vm.selectedAsset)?_c('span',{staticClass:"close",on:{"click":[() => _vm.deleteItem(_vm.selectedAsset.id),function($event){$event.stopPropagation();}]}},[_vm._v("×")]):_vm._e(),(_vm.hasSelectedAsset)?_c('span',{staticClass:"info"},[_vm._v(_vm._s(_vm.selectedAsset.asset_type === "image" ? _vm.formattedMetaInfo.resolution : _vm.formattedMetaInfo.duration))]):_vm._e()]),_c('div',{staticClass:"d-flex d-flex-r d-flex-between align-center meta-info"},[(_vm.hasSelectedAsset)?_c('span',[_vm._v(_vm._s(_vm.formattedMetaInfo.uploader))]):_vm._e(),_c('button',{staticClass:"edit-button edit ml-1",on:{"click":function($event){_vm.setActiveModal({
            name: 'AssetDetailsModal',
            props: {
              assetId: _vm.selectedAsset.id,
            },
            modalSubmit: () => ({}),
          })}}},[_vm._v(" Show details ")])])]),_c('div',{staticClass:"d-flex d-flex-r ml-1 assets"},_vm._l((_vm.assets),function(asset,index){return _c('object',{key:index,class:[
        'asset',
        { 'is-selected': !!_vm.selectedAsset && asset.id === _vm.selectedAsset.id },
      ],on:{"click":() => _vm.selectAsset(index)}},[(asset.media_url)?_c('img',{attrs:{"src":asset.asset_type === 'image' ? asset.media_url : asset.thumbnail_url},on:{"error":_vm.defaultBg}}):_vm._e()])}),0),_c('validation-provider',{attrs:{"mode":"aggressive"}},[_c('div',{staticClass:"d-flex d-flex-c hidden-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.validationValue),expression:"validationValue"}],ref:_vm.validationRef,attrs:{"name":"assets-preview-input"},domProps:{"value":(_vm.validationValue)},on:{"input":function($event){if($event.target.composing)return;_vm.validationValue=$event.target.value}}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }