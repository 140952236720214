<template>
  <div class="d-flex d-flex-r flex-1 d-flex-between">
    <div class="d-flex d-flex-c">
      <span class="empty-member mb-2" />
      <span class="empty-text" v-html="emptyText" />
    </div>
    <div class="d-flex right">
      <span class="empty-role" />
    </div>
  </div>
</template>

<script>
const emptyTexts = {
  team: '<p>You do not have participants in this team.</p><p>Click on "<b>Invite members</b>" to invite people.</p>',
  personal:
    "<p>You are currently in your personal environment.</p><p>Switch your current context to start managing and inspecting your team.</p>",
};
export default {
  name: "EmptyState",
  props: {
    context: { type: String, default: "" },
  },
  computed: {
    emptyText() {
      return emptyTexts[this.context];
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-member {
  width: 420px;
  height: 416px;
  background-image: url("/assets/images/layout/dashboard/team/empty_left.svg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 6px;
}

.empty-role {
  width: 580px;
  height: 620px;
  background-image: url("/assets/images/layout/dashboard/team/empty_right.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.empty-text {
  text-align: center;
}

.right {
  width: 50%;
  background-color: #f9f9f9;
  padding: 1em;
  border: 6px;
}
</style>
