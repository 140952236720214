<template>
  <div class="d-flex d-flex-r flex-1 d-flex-between">
    <div class="d-flex d-flex-c">
      <span class="empty-library mb-1 mt-05" />
      <span class="empty-text">
        <p>Your media library is empty. Click on</p>
        <p><b>"Create media library"</b> to start managing your content</p>
      </span>
    </div>
    <div class="d-flex" style="width: 50%">
      <span class="empty-assets" />
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyState",
};
</script>

<style lang="scss" scoped>
.empty-library {
  width: 565px;
  height: 322px;
  background-image: url("/assets/images/layout/dashboard/library/library_empty.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.empty-assets {
  width: 430px;
  height: 450px;
  background-image: url("/assets/images/layout/dashboard/library/assets_empty.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.empty-text {
  text-align: center;
}
</style>
