<template>
  <div class="content">
    <empty-state v-if="isEmpty" />
    <div v-else class="d-flex flex-1">
      <div class="d-flex d-flex-c content-left">
        <div class="tabs-wrapper">
          <div
            v-for="(tab, index) in tabs"
            :class="['tab', { selected: activeTab === tab.name }]"
            :key="index"
            @click="selectTab(tab.name)"
          >
            <label class="tab-label">{{ tab.label }}</label>
          </div>
        </div>

        <div
          class="d-flex d-flex-c d-flex-between"
          style="overflow: hidden; padding-right: 1em"
        >
          <div class="content-displays-wrapper">
            <project-display
              v-for="(project, index) in projects"
              :key="index"
              :project_name="project.name"
              :project_picture="project.project_picture"
              @display-click="selectProject(project)"
            />
          </div>
        </div>
      </div>
      <div class="content-right">
        <div
          :class="['content-items', 'projects', { empty: !!!selectedProject }]"
        >
          <project-edit
            :key="JSON.stringify(selectedProject)"
            v-if="selectedProject"
            :projectId="selectedProject.id"
            :projectName="selectedProject.name"
            :projectImage="selectedProject.project_picture"
            :channels="selectedChannels"
            :members="selectedProject.members"
            :timezone="selectedProject.timezone"
            @update-selection="updateSelection"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ProjectDisplay from "./ProjectDisplay.vue";
import ProjectEdit from "./ProjectEdit.vue";
import EmptyState from "./EmptyState.vue";

const PROJECTS_TABS = [{ name: "projects", label: "Projects" }];

export default {
  name: "Projects",
  components: { ProjectDisplay, ProjectEdit, EmptyState },
  data() {
    return {
      activeTab: "projects",
      tabs: PROJECTS_TABS,
      selectedProject: null,
    };
  },
  computed: {
    ...mapState({
      projects: (state) => state.project.projects,
      selectedChannels: function (state) {
        return state.channel.channels.filter(
          (channel) =>
            this.selectedProject &&
            this.selectedProject.channels.includes(channel.id),
        );
      },
    }),
    isEmpty() {
      return this.projects.length === 0;
    },
  },
  methods: {
    selectProject(project) {
      this.selectedProject = project;
    },
    selectTab(tab) {
      this.activeTab = tab;
    },
    updateSelection(updatedProject) {
      if (updatedProject) {
        this.selectedProject = updatedProject;
      } else {
        this.selectedProject = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  .content-right {
    .content-items {
      &.projects {
        &.empty {
          &::after {
            background-image: url("/assets/images/layout/dashboard/projects/no_projects.svg");
            background-repeat: no-repeat;
            background-position: center bottom;
            width: 100%;
            height: 100%;
            top: 0;
          }
        }
      }
    }
  }
}
</style>
