<template>
  <div
    :class="[
      'd-flex d-flex-r media-library-card',
      { 'is-selected': isSelected },
    ]"
    @click="$emit('card-click')"
  >
    <div class="d-flex d-flex-c d-flex-center info-left">
      <div class="d-flex d-flex-r mb-1">
        <span class="d-flex align-center"
          >{{ totalImages }} <label class="ml-05">Images</label></span
        >
      </div>
      <div class="d-flex d-flex-r">
        <span class="d-flex align-center"
          >{{ totalVideos }} <label class="ml-05">Videos</label></span
        >
      </div>
    </div>
    <div class="d-flex d-flex-c info-right">
      <span
        ><b>{{ cardInfo.name }}</b></span
      >
      <project-labels :projectLabels="projectLabels" />
    </div>
    <span class="delete" @click="deleteContent" />
  </div>
</template>

<script>
import ProjectLabels from "./ProjectLabels.vue";

export default {
  name: "MediaLibraryCard",
  components: { ProjectLabels },
  props: {
    cardIndex: { type: Number },
    cardInfo: { type: Object, default: () => ({}) },
    isSelected: { type: Boolean, default: false },
  },

  computed: {
    totalImages() {
      return this.cardInfo.asset_images.length;
    },
    totalVideos() {
      return this.cardInfo.asset_videos.length;
    },
    projectLabels() {
      return this.cardInfo.projects.map((project) => project.name);
    },
  },
  methods: {
    deleteContent() {
      this.$emit("delete-button-click", this.cardInfo.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.media-library-card {
  position: relative;
  min-width: 550px;
  height: 150px;
  border-radius: 6px;
  border: 1px solid #f9f9f9;
  cursor: pointer;

  &.is-selected {
    .info-left {
      background-color: #d5daef;
      color: black;
    }

    .info-right {
      background-color: #eeeeff;

      /deep/ .project-labels > label {
        background-color: white;
      }
    }
  }

  &:hover {
    .info-right {
      background-color: #aab4db;
    }
  }

  .info-left {
    min-width: 150px;
    font-size: 1.5rem;
    color: white;
    font-weight: 700;
    background-color: #2e2c3f;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;

    label {
      font-size: 1rem;
      font-weight: 400;
    }
  }

  .info-right {
    width: 100%;
    background-color: white;
    height: 100%;
    padding: 1em;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .delete {
    display: block;
    width: 24px;
    height: 24px;
    background-image: url("/assets/images/icons/common/trash_icon.webp");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 7px;
    right: 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.25rem;

    &:hover {
      background-color: white;
    }
  }
}
</style>
