<template>
  <div class="emoji-container" @click="toggleContainer">
    <v-emoji-picker
      v-if="toggled"
      @select="selectEmoji"
      :emojisByRow="8"
      :emojiSize="24"
      :showCategories="false"
      :showSearch="false"
    />
  </div>
</template>

<script>
import { VEmojiPicker } from "v-emoji-picker";
export default {
  name: "EmojiContainer",
  components: { VEmojiPicker },
  data() {
    return {
      toggled: false,
    };
  },
  methods: {
    toggleContainer() {
      this.toggled = !this.toggled;
    },
    selectEmoji(emoji) {
      this.$emit("insertEmoji", emoji.data);
    },
  },
};
</script>
<style lang="scss" scoped>
.emoji-container {
  position: relative;
  width: 25px;
  height: 25px;
  background-image: url("/assets/images/icons/dashboard/draft/emoji-picker.png");
  background-color: unset;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;

  .emoji-picker {
    position: absolute;
    bottom: 0;
    width: 400px;
    height: 250px;
  }
}
</style>
