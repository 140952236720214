<template>
  <div class="d-flex d-flex-c project-labels-wrapper">
    <span :class="['label mb-05', { 'is-bold': boldLabel }]"
      >Shared with below projects:</span
    >
    <div class="d-flex d-flex-r project-labels">
      <label v-for="(project, index) in projectLabels" :key="index">{{
        project
      }}</label>
    </div>
    <validation-provider v-if="value" mode="aggressive">
      <div class="d-flex d-flex-c hidden-input">
        <input
          :ref="validationRef"
          name="projectLabels"
          class="project-labels-input"
          v-model="validationValue"
        />
      </div>
    </validation-provider>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "ProjectLabels",
  props: {
    projectLabels: { type: Array, default: () => [] },
    boldLabel: { type: Boolean, default: false },
    value: { type: Array, default: null },
  },
  components: { ValidationProvider },
  data() {
    return {
      defaultValue: this.value,
      validationRef: "project-labels",
    };
  },
  mounted() {
    if (this.value) {
      // The input must be focused/blurred so that
      // the changed validation will trigger on value change
      this.$refs[this.validationRef].focus();
      this.$refs[this.validationRef].blur();
    }
  },
  computed: {
    validationValue() {
      const isDefault =
        this.value.length === this.defaultValue.length &&
        this.defaultValue.length &&
        this.defaultValue.every((v) => this.value.includes(v));
      return isDefault ? this.defaultValue.toString() : this.value.toString();
    },
  },
};
</script>

<style lang="scss" scoped>
.project-labels-wrapper {
  max-width: 300px;
  > span.label {
    font-size: 0.85rem;

    &.is-bold {
      font-weight: 700;
    }
  }
  .project-labels {
    flex-wrap: wrap;
    > label {
      padding: 0.5em 1em;
      background-color: #eeeeff;
      color: #221c55;
      font-size: 0.75rem;
      border-radius: 6px;
      margin-right: 0.5em;
      margin-bottom: 0.5em;
      text-transform: capitalize;
    }
  }
}
</style>
