var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-observer',{staticClass:"flex-1",scopedSlots:_vm._u([{key:"default",fn:function({ invalid, changed }){return [_c('div',{staticClass:"d-flex d-flex-c d-flex-between media-library-edit-wrapper"},[_c('div',{staticClass:"d-flex d-flex-c"},[_c('view-options',{staticClass:"mb-1",attrs:{"options":_vm.viewOptions},on:{"select-option":_vm.selectViewOption}}),_c('assets-preview',{staticClass:"mb-1",attrs:{"assets":_vm.assets},on:{"remove-item":_vm.removeAsset}}),_c('project-labels',{staticClass:"mb-1",attrs:{"projectLabels":_vm.projectLabels,"boldLabel":true},model:{value:(_vm.projectLabels),callback:function ($$v) {_vm.projectLabels=$$v},expression:"projectLabels"}}),_c('span',{staticClass:"action-label mb-1",on:{"click":function($event){return _vm.setActiveModal({
            name: 'ListModal',
            props: {
              items: _vm.listProjects,
              title: 'Share media library with:',
            },
            modalSubmit: _vm.modalSubmit,
          })}}},[_vm._v("Add / Remove projects")]),_c('content-input',{staticStyle:{"max-width":"250px"},attrs:{"name":"media_library_name","label":"Media library name"},model:{value:(_vm.libraryNameModel),callback:function ($$v) {_vm.libraryNameModel=$$v},expression:"libraryNameModel"}})],1),_c('div',{staticClass:"d-flex d-flex-r d-flex-between"},[_c('content-button',{attrs:{"label":"Upload"},on:{"button-click":function($event){_vm.setActiveModal({
            name: 'UploadModal',
            props: {
              mediaLibraryId: _vm.mediaLibraryId,
            },
            modalSubmit: () => ({}),
          })}}}),_c('content-button',{attrs:{"label":"Save","disabled":!changed || invalid},on:{"button-click":_vm.submit}})],1)])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }