<template>
  <div class="navbar mt-1">
    <nav class="nav">
      <button
        type="button"
        class="nav__button"
        title="Undo (Ctrl + Z)"
        :disabled="data.pristine || data.isSaving"
        @click="() => handleClick('restore')"
      >
        <span class="fa fa-undo" />
      </button>
      <button
        type="button"
        class="nav__button nav__button--danger"
        data-action="clear"
        title="Cancel (Esc)"
        :disabled="!data.cropping"
        @click="() => handleClick('clear')"
      >
        <span class="fa fa-ban" />
      </button>
      <button
        type="button"
        class="nav__button nav__button--success"
        data-action="crop"
        title="OK (Enter)"
        :disabled="!data.cropping"
        @click="() => handleClick('crop')"
      >
        <span class="fa fa-check" />
      </button>
      <button
        type="button"
        class="nav__button nav__button--success"
        data-action="save"
        title="Save changes"
        :disabled="data.pristine || data.isSaving"
        @click="() => handleClick('save')"
      >
        <span class="fa fa-floppy-o" />
      </button>
    </nav>
  </div>
</template>

<script>
export default {
  name: "EditorNav",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    handleClick(action) {
      if (action) {
        this.$emit("change", action);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  position: absolute;
  right: 0;
  bottom: 2em;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  border-radius: 6px;
  overflow: hidden;
}

.nav__button {
  background-color: transparent;
  border-width: 0;
  color: #fff;
  cursor: pointer;
  padding: 1em 1.5em;
  font-size: 1rem;

  &:disabled {
    color: #ccc;
    &:focus {
      outline: none;
    }

    &:hover {
      background-color: unset;
      cursor: initial;
      color: #ccc;
    }
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #aab4db;
    color: #fff;
  }
}

.nav--success:hover {
  background-color: #2ecc40;
}

.nav--danger:hover {
  background-color: #ff4136;
}
</style>
