<template>
  <div class="d-flex d-flex-r team-wrapper content">
    <empty-state v-if="isEmpty" />
    <div v-else class="d-flex d-flex-r d-flex-between flex-1">
      <div
        class="d-flex align-center time-frame-wrapper"
        @click="toggleRangeSelector"
      >
        Timeframe: <b class="ml-05">{{ intervalLabel }}</b>
      </div>
      <range-selector
        v-if="rangeSelectorToggled"
        :initialStartRange="startRange"
        :initialEndRange="endRange"
        :initialRangeIndex="rangeIndex"
        @apply-range="setRange"
        @close="toggleRangeSelector"
      />
      <div
        class="d-flex d-flex-r d-flex-between d-align-content-start flex-wrap"
        style="width: 50%"
      >
        <chart-wrapper
          label="Active members"
          wrapper-type="small"
          :chart-config="activeMembersConf"
        />
        <chart-wrapper
          label="Approved drafts"
          wrapper-type="small"
          :chart-config="approvedDraftsConf"
        />
        <chart-wrapper
          label="Pending drafts"
          wrapper-type="small"
          :chart-config="pendingDraftsConf"
        />

        <chart-wrapper
          label="Total content creation"
          chart-type="area"
          wrapper-type="medium"
          :chart-config="contentsCreatedConf"
        />
        <chart-wrapper
          label="Content types"
          chart-type="donut"
          wrapper-type="medium"
          :chart-config="contentTypesConf"
        />
        <chart-wrapper
          label="Scheduled posts"
          chart-type="line"
          wrapper-type="large"
          :chart-config="platformContentSeries"
          name="test"
        />
      </div>
      <div
        class="d-flex d-flex-r d-flex-between d-align-content-start flex-wrap"
        style="width: 49%"
      >
        <chart-wrapper
          label="Content creation activity"
          chart-type="bar"
          wrapper-type="large"
          :chart-config="monthlyContentConf"
        />
        <chart-wrapper
          label="Posted content on platforms"
          chart-type="donut"
          wrapper-type="medium"
          :chart-config="platformContentConf"
        />
        <chart-wrapper
          label="Posted comments"
          chart-type="donut"
          wrapper-type="medium"
          :chart-config="membersCommentsConf"
        />
      </div>
    </div>
    <full-size-loader
      v-if="isFetching"
      label="Fetching metrics"
      :loaderProps="loaderProps"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
// import { ContentButton } from "@/components/ui";
import EmptyState from "./EmptyState.vue";

import {
  generateRange,
  generateIntervalRange,
  isWithinRange,
  calculateDistance,
  sortDate,
  formatChartDate,
} from "@/utils/timezone";

import { metriclIntervals, customIntervalRanges } from "@/utils/constants";

export default {
  name: "Team",
  components: {
    EmptyState,
    ChartWrapper: () =>
      import("@/components/").then((res) => {
        return res.ChartWrapper;
      }),
    RangeSelector: () =>
      import("@/components/").then((res) => {
        return res.RangeSelector;
      }),
    FullSizeLoader: () =>
      import("@/components/").then((res) => res.FullSizeLoader),
  },
  data() {
    return {
      startRange: null,
      endRange: null,
      dashboardInsights: null,
      schduledPostOpts: null,
      selectedInterval: "monthly",
      rangeIndex: 4,
      intervalLabel: "Last 30 days",
      rangeSelectorToggled: false,
      loaderProps: {
        width: 25,
        height: 25,
      },
    };
  },
  computed: {
    ...mapState({
      teamMembers: (state) => state.team.currentTeam.members,
      teamSize: (state) => state.team.currentTeam.members.length,
      selectedProjectId: (state) => state.team.selectedProjectId,
      isFetching: (state) => state.team.status.requesting.getTeamInsights,
    }),
    isEmpty() {
      return !this.dashboardInsights;
    },
    activeMembersConf() {
      const {
        dashboardInsights: { team_members_metrics },
      } = this;

      const membersCount = team_members_metrics
        .filter((metric) => {
          if (this.selectedProjectId === null) {
            return true;
          } else {
            return metric.project_id === this.selectedProjectId;
          }
        })
        .reduce((acc, curr) => acc + curr.project_members, 0);

      const count = membersCount < this.teamSize ? membersCount : this.teamSize;
      return {
        count,
        team: true,
        teamCount: this.teamSize,
      };
    },
    pendingDraftsConf() {
      const {
        dashboardInsights: { team_pending_contents_metrics },
      } = this;

      const filteredMetrics = team_pending_contents_metrics.filter((metric) => {
        if (this.selectedProjectId === null) {
          return true;
        } else {
          return metric.project_id === this.selectedProjectId;
        }
      });

      const intervalOpts = metriclIntervals[this.selectedInterval];

      let dateSeries = null;
      if (this.selectedInterval === "custom") {
        dateSeries = generateRange(
          this.startRange,
          this.endRange,
          intervalOpts.steps,
        );
      } else {
        dateSeries = generateIntervalRange(
          intervalOpts.interval,
          intervalOpts.steps,
        );
      }

      const count = filteredMetrics.reduce((acc, curr) => {
        curr.series.forEach((c, i) => {
          if (isWithinRange(c.date, dateSeries[0])) {
            acc = acc + c.content_count;
          }
        });
        return acc;
      }, 0);

      return {
        count,
      };
    },
    approvedDraftsConf() {
      const {
        dashboardInsights: { team_approved_contents_metrics },
      } = this;

      const filteredMetrics = team_approved_contents_metrics.filter(
        (metric) => {
          if (this.selectedProjectId === null) {
            return true;
          } else {
            return metric.project_id === this.selectedProjectId;
          }
        },
      );

      const intervalOpts = metriclIntervals[this.selectedInterval];

      let dateSeries = null;
      if (this.selectedInterval === "custom") {
        dateSeries = generateRange(
          this.startRange,
          this.endRange,
          intervalOpts.steps,
        );
      } else {
        dateSeries = generateIntervalRange(
          intervalOpts.interval,
          intervalOpts.steps,
        );
      }

      const count = filteredMetrics.reduce((acc, curr) => {
        curr.series.forEach((c, i) => {
          if (isWithinRange(c.date, dateSeries[0])) {
            acc = acc + c.content_count;
          }
        });
        return acc;
      }, 0);
      return {
        count,
      };
    },
    contentsCreatedConf() {
      const {
        dashboardInsights: { team_contents_series },
      } = this;

      const filteredMetrics = team_contents_series.filter((t) => {
        if (this.selectedProjectId === null) {
          return true;
        } else {
          return t.project_id === this.selectedProjectId;
        }
      });

      const count = filteredMetrics.reduce(
        (acc, curr) =>
          acc + curr.series.reduce((a, c) => a + c.content_count, 0),
        0,
      );

      const dates = [
        ...new Set(
          filteredMetrics.reduce((acc, curr) => {
            acc = acc.concat(curr.series.map((c) => c.date));
            return acc;
          }, []),
        ),
      ];

      const intervalOpts = metriclIntervals[this.selectedInterval];
      let dateSeries = null;
      if (this.selectedInterval === "custom") {
        dateSeries = generateRange(
          this.startRange,
          this.endRange,
          intervalOpts.steps,
        );
      } else {
        dateSeries = generateIntervalRange(
          intervalOpts.interval,
          intervalOpts.steps,
        );
      }

      const xaxis = [
        ...new Set(
          [...dates, ...dateSeries]
            .sort(sortDate)
            .filter((date) => isWithinRange(date, dateSeries[0])),
        ),
      ];

      const data = filteredMetrics.reduce(
        (acc, curr) => {
          curr.series.forEach((c, i) => {
            const j = xaxis.indexOf(c.date);
            acc[j] = acc[j] + c.content_count;
          });
          return acc;
        },
        Array.from(xaxis).map((o) => 0),
      );

      const series = [
        {
          name: "Content",
          data,
        },
      ];

      const chartOptions = {
        grid: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 3,
        },
        fill: {
          type: "gradient",
          gradient: {
            opacityFrom: 0.5,
            opacityTo: 0.3,
            stops: [20, 100, 100, 100],
          },
        },
        xaxis: {
          categories: xaxis,
          labels: {
            show: false,
            format: "dd/MM",
            formatter: function (value) {
              return formatChartDate(value, "MM-DD");
            },
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
      };
      return {
        series,
        count,
        chartOptions,
      };
    },
    contentTypesConf() {
      const {
        dashboardInsights: { team_contents_metrics },
      } = this;

      const filteredMetrics = team_contents_metrics.filter((metric) => {
        if (this.selectedProjectId === null) {
          return true;
        } else {
          return metric.project_id === this.selectedProjectId;
        }
      });

      const intervalOpts = metriclIntervals[this.selectedInterval];
      let dateSeries = null;
      if (this.selectedInterval === "custom") {
        dateSeries = generateRange(
          this.startRange,
          this.endRange,
          intervalOpts.steps,
        );
      } else {
        dateSeries = generateIntervalRange(
          intervalOpts.interval,
          intervalOpts.steps,
        );
      }

      const data = filteredMetrics.reduce((acc, cval) => {
        cval.series
          .filter((c) => isWithinRange(c.date, dateSeries[0]))
          .forEach((v) => {
            if (acc.hasOwnProperty(v.status)) {
              acc[v.status] = acc[v.status] + v.content_count;
            } else {
              acc[v.status] = v.content_count;
            }
          });
        return acc;
      }, {});

      const labels = Object.keys(data);
      const series = Object.values(data);
      const count = series.reduce((acc, cur) => acc + cur, 0);

      const chartOptions = {
        labels,
        colors: ["#1B59F8", "#4d7df9", "#e5e5e5"],
      };

      return {
        series,
        count,
        chartOptions,
      };
    },
    platformContentSeries() {
      const {
        dashboardInsights: { team_platform_contents_series },
      } = this;

      const filteredMetrics = team_platform_contents_series.filter((metric) => {
        if (this.selectedProjectId === null) {
          return true;
        } else {
          return metric.project_id === this.selectedProjectId;
        }
      });

      const dates = [
        ...new Set(
          filteredMetrics.reduce((acc, curr) => {
            acc = acc.concat(curr.series.map((c) => c.date));
            return acc;
          }, []),
        ),
      ];

      const intervalOpts = metriclIntervals[this.selectedInterval];
      let dateSeries = null;
      if (this.selectedInterval === "custom") {
        dateSeries = generateRange(
          this.startRange,
          this.endRange,
          intervalOpts.steps,
        );
      } else {
        dateSeries = generateIntervalRange(
          intervalOpts.interval,
          intervalOpts.steps,
        );
      }

      const xaxis = [
        ...new Set(
          [...dates, ...dateSeries]
            .sort(sortDate)
            .filter((date) => isWithinRange(date, dateSeries[0])),
        ),
      ];

      const seriesObj = filteredMetrics.reduce((acc, curr) => {
        curr.series.forEach((c, i) => {
          if (acc.hasOwnProperty(c.platform_type)) {
            const xi = xaxis.indexOf(c.date);
            if (xi) {
              acc[c.platform_type][xi] =
                acc[c.platform_type][xi] + c.content_count;
            }
          } else {
            acc[c.platform_type] = Array.from(xaxis).map((o, j) => {
              if (o === c.date) {
                return c.content_count;
              } else {
                return 0;
              }
            });
          }
        });
        return acc;
      }, {});

      const series = Object.entries(seriesObj).map(([key, val]) => ({
        name: key,
        data: val,
      }));

      const chartOptions = {
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        stroke: {
          curve: "smooth",
          width: 3,
        },
        colors: ["#0177FB", "#FF7E02", "#FE6CB9"],
        ...{
          xaxis: {
            categories: xaxis,
            tickAmount: 6,
            labels: {
              formatter: function (value) {
                return formatChartDate(value, "MM-DD");
              },
              offsetY: 5,
            },
            axisBorder: {
              show: false,
            },
          },
        },
        yaxis: {
          min: 0,
          tickAmount: 4,
          labels: {
            offsetX: -20,
          },
          axisBorder: {
            show: false,
          },
        },
        legend: {
          position: "top",
        },
      };

      return {
        series,
        chartOptions,
      };
    },
    monthlyContentConf() {
      const {
        dashboardInsights: { team_monthly_contents_series },
      } = this;

      const filteredMetrics = team_monthly_contents_series.filter((metric) => {
        if (this.selectedProjectId === null) {
          return true;
        } else {
          return metric.project_id === this.selectedProjectId;
        }
      });

      const xaxisCategores = filteredMetrics[0]
        ? filteredMetrics[0].series.map((s) => s.date).sort(sortDate)
        : [];

      const data = filteredMetrics.reduce((acc, curr) => {
        curr.series.forEach((c, i) => {
          if (acc[i]) {
            acc[i] = acc[i] + c.content_count;
          } else {
            acc[i] = c.content_count;
          }
        });
        return acc;
      }, []);

      const series = [
        {
          name: "Monthly Content",
          data,
        },
      ];

      const chartOptions = {
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          stroke: {
            width: 50,
            colors: ["#000"],
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 6,
            columnWidth: "35%",
          },
        },
        xaxis: {
          categories: xaxisCategores,
          labels: {
            formatter: function (value) {
              return formatChartDate(value, "MMM");
            },
            offsetY: 5,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {},
      };

      return {
        series,
        chartOptions,
      };
    },
    platformContentConf() {
      const {
        dashboardInsights: { team_platform_contents_series },
      } = this;

      const filteredMetrics = team_platform_contents_series.filter((metric) => {
        if (this.selectedProjectId === null) {
          return true;
        } else {
          return metric.project_id === this.selectedProjectId;
        }
      });

      const intervalOpts = metriclIntervals[this.selectedInterval];
      let dateSeries = null;
      if (this.selectedInterval === "custom") {
        dateSeries = generateRange(
          this.startRange,
          this.endRange,
          intervalOpts.steps,
        );
      } else {
        dateSeries = generateIntervalRange(
          intervalOpts.interval,
          intervalOpts.steps,
        );
      }

      const data = filteredMetrics.reduce((acc, cval) => {
        cval.series
          .filter((c) => isWithinRange(c.date, dateSeries[0]))
          .forEach((v) => {
            if (acc.hasOwnProperty(v.platform_type)) {
              acc[v.platform_type] = acc[v.platform_type] + v.content_count;
            } else {
              acc[v.platform_type] = v.content_count;
            }
          });
        return acc;
      }, {});

      const labels = Object.keys(data);
      const series = Object.values(data);
      const count = series.reduce((acc, cur) => acc + cur, 0);

      const chartOptions = {
        labels,

        colors: ["#0190ff", "#FFB737", "#FE6CB9", "#C7AFD3"],
      };

      return {
        series,
        count,
        chartOptions,
      };
    },
    membersCommentsConf() {
      const {
        dashboardInsights: { team_comments_metrics },
      } = this;

      const filteredMetrics = team_comments_metrics.filter((metric) => {
        if (this.selectedProjectId === null) {
          return true;
        } else {
          return metric.project_id === this.selectedProjectId;
        }
      });

      const intervalOpts = metriclIntervals[this.selectedInterval];
      let dateSeries = null;
      if (this.selectedInterval === "custom") {
        dateSeries = generateRange(
          this.startRange,
          this.endRange,
          intervalOpts.steps,
        );
      } else {
        dateSeries = generateIntervalRange(
          intervalOpts.interval,
          intervalOpts.steps,
        );
      }

      const data = filteredMetrics.reduce((acc, cval) => {
        cval.series
          .filter((c) => isWithinRange(c.date, dateSeries[0]))
          .forEach((v) => {
            if (acc.hasOwnProperty(v.user_id)) {
              acc[v.user_id] = acc[v.user_id] + v.message_count;
            } else {
              acc[v.user_id] = v.message_count;
            }
          });
        return acc;
      }, {});

      const labels = Object.keys(data).map(
        (key) =>
          (this.teamMembers.find((m) => m.id === key) || {}).full_name ||
          "Member",
      );

      const series = Object.values(data);
      const count = series.reduce((acc, cur) => acc + cur, 0);

      const chartOptions = {
        labels,

        colors: ["#0190ff", "#FFB737", "#FE6CB9", "#C7AFD3"],
      };

      return {
        chartOptions,
        series,
        count,
      };
    },
  },
  async created() {
    const params = { interval: 30 };
    this.getTeamInsights(params).then((res) => {
      this.dashboardInsights = res;
    });
  },
  methods: {
    ...mapActions({
      getTeamInsights: "getTeamInsights",
    }),
    toggleRangeSelector(val) {
      if (val) {
        this.rangeSelectorToggled = val;
      } else {
        this.rangeSelectorToggled = !this.rangeSelectorToggled;
      }
    },
    setRange(data) {
      const frequency = data.timeframeData
        ? data.timeframeData.frequency
        : "custom";

      const params = {
        ...(frequency === "custom"
          ? {
              start: data.ranges.startRange.format("YYYY-MM-DD"),
              end: data.ranges.endRange.format("YYYY-MM-DD"),
            }
          : {
              interval: metriclIntervals[frequency].interval,
            }),
      };
      const label =
        frequency === "custom"
          ? `${data.ranges.startRange.format(
              "DD MMM YYYY",
            )} - ${data.ranges.endRange.format("DD MMM YYYY")}`
          : data.timeframeData.label;

      this.intervalLabel = label;
      this.getTeamInsights(params).then((res) => {
        if (frequency === "custom") {
          const diff = calculateDistance(
            data.ranges.startRange,
            data.ranges.endRange,
          );

          metriclIntervals[frequency] = {
            ...customIntervalRanges.find((range) => range.interval <= diff),
          };
        }
        if (data.rangeIndex) {
          this.rangeIndex = data.rangeIndex;
        }
        this.startRange = data.ranges.startRange;
        this.endRange = data.ranges.endRange;
        this.dashboardInsights = res;
        this.selectedInterval = frequency;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.team-wrapper {
  height: 100%;
  background-color: transparent !important;

  &.insights {
    margin-top: 0 !important;
  }

  .range-selector {
    position: absolute;
    right: 0;
  }

  .time-frame-wrapper {
    position: absolute;
    height: 35px;
    min-width: 200px;
    padding: 0 1em;
    right: 1em;
    top: -45px;
    border-radius: 20px;
    background-color: white;
    cursor: pointer;
    z-index: 1050;

    b {
      font-weight: 500;
    }
  }
}
</style>
