var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex d-flex-c d-flex-between image-editor"},[(_vm.data.isSaving)?_c('div',{staticClass:"d-flex align-center save-status"},[_c('span',{staticClass:"mr-05"},[_vm._v("Saving")]),_vm._v(" "),_c('spinner',{attrs:{"width":18,"height":18}})],1):_vm._e(),_c('div',{class:['canvas', { 'filters-toggled': _vm.filtersToggled }],on:{"dblclick":_vm.dblclick}},[_c('img',{ref:_vm.imgRef,class:[{ 'is-loading': _vm.loadingImage }],attrs:{"alt":_vm.data.name,"src":_vm.data.url},on:{"load":_vm.start,"loadstart":_vm.start}})]),_c('div',{staticClass:"d-flex d-flex-r d-flex-center"},[_c('div',{staticClass:"d-flex d-flex-c mt-1 options-wrapper"},[(_vm.selectedFilterOption)?_c('div',{staticClass:"d-flex d-flex-r d-flex-between slider"},[_c('div',{staticClass:"filter-info mr-1"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(_vm.selectedFilterOption))])]),_c('input',{ref:_vm.sliderRef,staticClass:"flex-1",attrs:{"type":"range","min":_vm.filterMin,"max":_vm.filterMax},domProps:{"value":_vm.filterOptions[_vm.selectedFilterOption]},on:{"input":_vm.updateFilter,"mouseup":_vm.addFilterHistory,"mousedown":_vm.takeFilterSnapshot}})]):_vm._e(),(_vm.filtersToggled)?_c('div',{staticClass:"d-flex d-flex-r d-flex-between align-center filter-toolbar",on:{"click":_vm.click}},[_c('button',{class:[
            'toolbar__button',
            { 'is-selected': _vm.selectedFilterOption === 'brightness' },
          ],attrs:{"title":"Brightness"},on:{"click":() => _vm.toggleSlider('brightness', 0, 200)}},[_c('span',{staticClass:"fa fa-certificate"})]),_c('button',{class:[
            'toolbar__button',
            { 'is-selected': _vm.selectedFilterOption === 'contrast' },
          ],attrs:{"title":"Contrast"},on:{"click":() => _vm.toggleSlider('contrast', 0, 100)}},[_c('span',{staticClass:"fa fa-adjust"})]),_c('button',{class:[
            'toolbar__button',
            { 'is-selected': _vm.selectedFilterOption === 'saturation' },
          ],attrs:{"title":"Saturation"},on:{"click":() => _vm.toggleSlider('saturation', 0, 200)}},[_c('span',{staticClass:"fa fa-sun-o"})]),_c('button',{class:[
            'toolbar__button',
            { 'is-selected': _vm.selectedFilterOption === 'inversion' },
          ],attrs:{"title":"Inversion"},on:{"click":() => _vm.toggleSlider('inversion', 0, 100)}},[_c('span',{staticClass:"fa fa-tint"})]),_c('button',{class:[
            'toolbar__button',
            { 'is-selected': _vm.selectedFilterOption === 'blur' },
          ],attrs:{"title":"Blur"},on:{"click":() => _vm.toggleSlider('blur', 0, 10)}},[_c('span',{staticClass:"fa fa-spinner"})]),_c('button',{class:[
            'toolbar__button',
            { 'is-selected': _vm.selectedFilterOption === 'hue' },
          ],attrs:{"title":"Hue"},on:{"click":() => _vm.toggleSlider('hue', 0, 360)}},[_c('span',{staticClass:"fa fa-lightbulb-o"})]),_c('button',{class:[
            'toolbar__button',
            { 'is-selected': _vm.selectedFilterOption === 'grayscale' },
          ],attrs:{"title":"Grayscale"},on:{"click":() => _vm.toggleSlider('grayscale', 0, 100)}},[_c('span',{staticClass:"fa fa-circle",staticStyle:{"margin-right":"-3px"}}),_c('span',{staticClass:"fa fa-circle-thin",staticStyle:{"margin-left":"-3px"}})]),_c('button',{class:[
            'toolbar__button',
            { 'is-selected': _vm.selectedFilterOption === 'sepia' },
          ],attrs:{"title":"Sepia"},on:{"click":() => _vm.toggleSlider('sepia', 0, 100)}},[_c('span',{staticClass:"fa fa-picture-o"})])]):_vm._e(),_c('div',{class:[
          'd-flex d-flex-r d-flex-between align-center toolbar',
          { 'filters-toggled': _vm.filtersToggled },
        ],on:{"click":_vm.click}},[_c('button',{class:['toolbar__button', { 'is-selected': _vm.mode === 'move' }],attrs:{"data-action":"move","title":"Move (M)"}},[_c('span',{staticClass:"fa fa-arrows"})]),_c('button',{class:['toolbar__button', { 'is-selected': _vm.mode === 'crop' }],attrs:{"data-action":"crop","title":"Crop (C)"}},[_c('span',{staticClass:"fa fa-crop"})]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('button',{staticClass:"toolbar__button",attrs:{"title":"Filters"},on:{"click":_vm.toggleFilters}},[_c('span',{staticClass:"fa fa-sliders"})])])]),_c('editor-nav',{attrs:{"data":_vm.data},on:{"change":_vm.handleChange}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"toolbar__button",attrs:{"data-action":"zoom-in","title":"Zoom In (I)"}},[_c('span',{staticClass:"fa fa-search-plus"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"toolbar__button",attrs:{"data-action":"zoom-out","title":"Zoom Out (O)"}},[_c('span',{staticClass:"fa fa-search-minus"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"toolbar__button",attrs:{"data-action":"rotate-left","title":"Rotate Left (L)"}},[_c('span',{staticClass:"fa fa-rotate-left"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"toolbar__button",attrs:{"data-action":"rotate-right","title":"Rotate Right (R)"}},[_c('span',{staticClass:"fa fa-rotate-right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"toolbar__button",attrs:{"data-action":"flip-horizontal","title":"Flip Horizontal (H)"}},[_c('span',{staticClass:"fa fa-arrows-h"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"toolbar__button",attrs:{"data-action":"flip-vertical","title":"Flip Vertical (V)"}},[_c('span',{staticClass:"fa fa-arrows-v"})])
}]

export { render, staticRenderFns }