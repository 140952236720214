<template>
  <div class="content">
    <div class="d-flex d-flex-c content-left">
      <view-options
        class="mb-1"
        :options="filteredViewOptions"
        @select-option="selectViewIndex"
      />
      <div
        class="d-flex d-flex-c d-flex-between"
        style="overflow: hidden; padding-right: 1em"
      >
        <content-cards-wrapper
          :cards="drafts"
          @card-click="selectDraft"
          :path-context="pathContext"
        />
      </div>
    </div>
    <div class="content-right">
      <div :class="['content-items', 'drafts', { empty: !!!selectedDraft }]">
        <draft-chat
          v-if="selectedDraft"
          :content_id="selectedDraft.id"
          :messages="selectedDraft.messages"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ContentCardsWrapper } from "@/components/dashboard";
import { ViewOptions } from "@/components/ui";
import DraftChat from "./DraftChat";

const VIEW_OPTIONS = ["approved", "pending"];
export default {
  name: "Drafts",
  components: { ContentCardsWrapper, DraftChat, ViewOptions },
  data() {
    return {
      viewOptions: VIEW_OPTIONS,
      selectedViewIndex: 0,
      selectedDraft: null,
      pathContext: "/drafts/edit",
    };
  },
  computed: {
    ...mapState({
      drafts: function (state) {
        return state.content.drafts
          .filter((draft) => {
            if (state.content.draftSortSettings.selectedProjectId === null) {
              return true;
            } else {
              if (
                draft.project_id ===
                state.content.draftSortSettings.selectedProjectId
              ) {
                return true;
              } else {
                return false;
              }
            }
          })
          .filter(
            (draft) =>
              draft.status === this.filteredViewOptions[this.selectedViewIndex],
          );
      },
      isTeamContext: (state) => !!state.team.currentTeam.id,
    }),
    filteredViewOptions() {
      return this.viewOptions.filter((opt) => {
        if (opt !== "approved" && !this.isTeamContext) {
          return opt;
        } else if (this.isTeamContext) {
          return opt;
        }
      });
    },
  },
  watch: {
    drafts(newValue) {
      if (this.selectedDraft) {
        const found = newValue.find(
          (draft) => draft.id === this.selectedDraft.id,
        );
        if (!found) {
          this.selectedDraft = null;
        }
      }
    },
  },
  methods: {
    selectViewIndex(index) {
      this.selectedViewIndex = index;
    },
    selectDraft(index) {
      this.selectedDraft = this.drafts[index];
    },
  },
};
</script>
<style lang="scss">
.content {
  .content-left {
    .drafts-wrapper {
      padding-top: 1em;
      padding-bottom: 1em;
    }
  }
  .content-right {
    .content-items {
      &.drafts {
        padding: 0 !important;
        &.empty {
          padding: 1em !important;
          &::after {
            background-image: url("/assets/images/layout/dashboard/schedule/no_posts.svg");
            background-repeat: no-repeat;
            background-position: center bottom;
            background-size: contain;
            top: 0;
            width: 95%;
            height: 100%;
          }
        }
        .content-input {
          max-width: 350px;
        }
        .container {
          max-width: 350px;
        }
      }
    }
  }
}
</style>
