<template>
  <div
    v-if="render"
    class="preview-image"
    :style="`background-image: url(${bgImg}); max-width: ${width}}px; height: ${height}px}`"
  >
    <span v-if="isMore" class="collage-more">{{ `${isMoreLength}+` }}</span>
  </div>
</template>

<script>
export default {
  name: "ImageItem",
  props: {
    bgImg: { type: String, default: "" },
    width: { type: Number },
    height: { type: Number },
    isMore: { type: Boolean, default: false },
    render: { type: Boolean, default: false },
    isMoreLength: { type: Number },
  },
};
</script>

<style lang="scss">
.preview-image {
  .collage-more {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    color: #fff;
    font-size: 3rem;
    background-color: rgba(0, 0, 0, 0.4);
  }
}
</style>
