<template>
  <div class="d-flex d-flex-r media-wrapper content">
    <empty-state v-if="isEmpty" />

    <div v-else class="d-flex d-flex-r flex-1">
      <div class="d-flex d-flex-c content-left">
        <cards-wrapper
          :cards="mediaLibraries"
          @card-click="selectmediaLibrary"
        />
      </div>
      <div
        :class="[
          'd-flex d-flex-c content-right ml-1',
          { empty: !!!selectedMediaLibrary },
        ]"
      >
        <media-library-edit
          v-if="selectedMediaLibrary"
          :key="selectionTimeStamp"
          :mediaLibraryId="selectedMediaLibrary.id"
          :mediaLibraryName="selectedMediaLibrary.name"
          :projects="selectedMediaLibrary.projects"
          @update-selection="updateSelection"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CardsWrapper from "./CardsWrapper.vue";
import MediaLibraryEdit from "./MediaLibraryEdit.vue";
import EmptyState from "./EmptyState.vue";

export default {
  name: "Media",
  components: { CardsWrapper, MediaLibraryEdit, EmptyState },
  created() {
    this.selectedMediaLibrary = this.mediaLibraries[0];
  },
  data() {
    return {
      selectedMediaLibrary: null,
      selectionTimeStamp: Date.now(),
    };
  },
  computed: {
    ...mapState({
      mediaLibraries: (state) =>
        state.library.mediaLibraries.map((library) => ({
          ...library,
          projects: state.project.projects.filter((project) =>
            library.projects.includes(project.id),
          ),
        })),
    }),
    isEmpty() {
      return this.mediaLibraries.length === 0;
    },
  },
  methods: {
    selectmediaLibrary(index) {
      this.selectedMediaLibrary = this.mediaLibraries[index];
      this.selectionTimeStamp = Date.now();
    },
    updateSelection(updatedLibrary) {
      if (updatedLibrary) {
        this.selectedMediaLibrary = this.mediaLibraries.find(
          (library) => library.id === updatedLibrary.id,
        );
        this.selectionTimeStamp = Date.now();
      } else {
        this.selectedMediaLibrary = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.media-wrapper {
  .content-left {
    padding-right: 1em;
    flex: 2 !important;
  }

  .content-right {
    flex: 2 !important;
  }
}
</style>
