<template>
  <validation-provider v-slot="{ errors }" rules="required" mode="aggressive">
    <div class="d-flex d-flex-c content-text-container">
      <div class="spinner-container">
        <spinner
          v-if="isRequesting"
          color="#ccc"
          :width="36"
          :height="36"
          :label-size="14"
          :label="textEllipsis"
        />
      </div>
      <textarea
        :ref="textAreaRef"
        id="text"
        name="text"
        placeholder="Type your text here..."
        v-model="modelValue"
        :disabled="isRequesting"
      />

      <div
        class="d-flex d-flex-r d-flex-between align-center post-text-options"
      >
        <div class="d-flexx d-flex-r">
          <emoji-container @insertEmoji="insertEmoji" />
        </div>
        <blank-action-button
          label="A.I. assistance"
          :items="postTextMenu"
          @menu-item-select="handleMenuSelect"
        />
      </div>
    </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { Spinner, BlankActionButton } from "@/components";
import EmojiContainer from "@/components/dashboard/content/drafts/EmojiContainer.vue";
// import { debounce } from "@/utils/functions";

const postTextMenu = [
  {
    label: "Optimize text",
    class: "optimize",
    action: "optimize",
    enabled: false,
  },
  {
    label: "Generate hashtags",
    class: "hashtags",
    action: "generateHashtags",
    enabled: true,
  },
  {
    label: "Revise text",
    class: "revise",
    action: "reviseText",
    enabled: true,
  },
];
export default {
  name: "ContentText",
  components: {
    EmojiContainer,
    Spinner,
    BlankActionButton,
    ValidationProvider,
  },
  props: {
    text: { type: String, default: "" },
    isRequesting: { type: Boolean, default: false },
    requestingText: { type: String, default: "" },
    customizePost: { type: Boolean, default: false },
  },
  data() {
    return {
      textAreaRef: "content-text-area",
      modelValue: this.text,
    };
  },
  computed: {
    textEllipsis() {
      return `${this.requestingText}...`;
    },
    postTextMenu() {
      return postTextMenu.map((item) => ({
        ...item,
        enabled: this.customizePost || item.enabled,
      }));
    },
  },
  watch: {
    modelValue(newVal) {
      //  console.log("wop wop wop", newVal);
      this.$emit("on-input-change", newVal);
    },
    // modelValue: debounce(function (newVal) {
    //   this.$emit("on-input-change", newVal);
    // }, 500),
    text(newVal) {
      this.modelValue = newVal;
    },
  },
  methods: {
    insertEmoji(emoji) {
      const cursorPosition = this.$refs[this.textAreaRef].selectionStart;
      const emojiText =
        this.modelValue.substring(0, cursorPosition) +
        emoji +
        this.modelValue.substring(cursorPosition);
      this.modelValue = emojiText;
    },
    handleMenuSelect(item) {
      this.$emit("ai-assitance-click", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.content-text-container {
  position: relative;
  textarea {
    display: flex;
    flex: 1;
    background: transparent;
    width: 100%;
    min-height: 190px;
    border: none;
    resize: none;
    outline: none;
  }
  .spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
