<template>
  <div :class="['d-flex d-flex-c full-width flex-1', platformLowerCase]">
    <div class="d-flex d-flex-r mb-05">
      <span class="platform-icon ml-05 mr-05"></span
      ><label class="platform">{{ platform }}</label>
    </div>
    <div class="d-flex d-flex-c preview-wrapper">
      <div class="d-flex d-flex-r d-flex-between preview-top">
        <div class="d-flex d-flex-r channel-wrapper">
          <span class="profile-avatar mr-05" :style="setBg(channelImage)" />
          <div class="d-flex d-flex-c">
            <span class="d-flex d-flex-c d-justify-center channel-name"
              ><b>{{ channelName }}</b></span
            >
            <span class="channel-meta" />
          </div>
        </div>
        <span class="opts" />
      </div>
      <div class="d-flex d-flex-c preview-bottom">
        <div class="d-flex mt-05 mb-1 text-preview">
          <pre style="white-space: pre-wrap">{{ text }}</pre>
        </div>
        <div
          :class="[
            'd-flex d-flex-c mb-05 image-preview',
            {
              'initial-image': !hasAssets && platformLowerCase === 'instagram',
            },
          ]"
        >
          <image-preview
            v-if="hasAssets"
            :platform="platform"
            :images="assets"
          />
        </div>
        <div class="d-flex d-flex-r d-flex-between social-buttons mt-2">
          <div
            v-for="(action, index) in actionButtons"
            :key="index"
            class="d-flex d-flex-r align-center"
          >
            <span :class="['action-icon mr-05', action]" />
            <label :class="['action-label', action]">{{ action }}</label>
          </div>
          <ul
            v-if="platformLowerCase === 'instagram' && isMultiImage"
            class="preview-indicators"
            aria-hidden
          >
            <li
              v-for="(_, index) in assets"
              :key="index"
              class="preview-indicator"
            />
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { platforms } from "@/utils/constants";

import ImagePreview from "./ImagePreview.vue";

export default {
  name: "PostPreview",
  components: { ImagePreview },
  props: {
    platform: { type: String, default: "" },
    text: { type: String, default: "" },
    assetIds: {
      type: Array,
      default: () => [],
    },
    channelName: { type: String, default: "" },
    channelImage: { type: String, default: "" },
  },
  data() {
    return {
      actionButtons: platforms[this.platform].actions,
    };
  },
  computed: {
    ...mapState({
      assets: function (state) {
        return state.asset.assets.filter((asset) =>
          this.assetIds.includes(asset.id),
        );
      },
    }),
    platformLowerCase() {
      return this.platform.toLowerCase();
    },
    hasAssets() {
      return this.assets && this.assets.length > 0;
    },
    isMultiImage() {
      return this.assets && this.assets.length > 1;
    },
  },
  methods: {
    setBg(bgImage) {
      return bgImage && bgImage.length > 0
        ? `background-image: url("${bgImage}")`
        : "background-image: url('/assets/images/icons/dashboard/sidemenu/profile_icon.svg')";
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-wrapper {
  background-color: white;
  border-radius: 6px;
  padding: 1em 0;
  overflow: hidden;

  .preview-indicators {
    list-style: none;
    padding: 10px;
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    .preview-indicator {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: grey;
      margin: 0 2px;
      &.active {
        background-color: #0084db;
      }
    }
  }

  .preview-top {
    padding: 0 1em;
    .profile-avatar {
      width: 48px;
      height: 48px;
      border-radius: 100%;
      background-size: contain;
    }

    .opts {
      background-image: url("/assets/images/icons/social/shared/dots.svg");
      background-repeat: no-repeat;
      width: 16px;
      height: 4px;
    }
  }

  .preview-bottom {
    position: relative;
    .text-preview {
      word-wrap: anywhere;
      padding: 0 1em;
    }
  }
  .social-buttons {
    position: relative;
    padding: 0 1em;
    .action-icon {
      width: 24px;
      height: 24px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
    label {
      text-transform: capitalize;
    }
  }
}
.platform-icon {
  height: 24px;
  width: 24px;
  background-repeat: no-repeat;
}

div.facebook {
  max-width: 680px;
  .platform-icon {
    background-image: url("/assets/images/icons/social/facebook_channel.png");
  }

  .preview-top {
    .opts {
      margin-top: 0.75em;
    }

    .channel-meta {
      line-height: 1rem;
      color: #717276;
      &::before {
        content: "Just now ·";
        font-size: 0.8rem;
      }
      &:after {
        content: "";
        display: inline-block;
        background-image: url("/assets/images/icons/social/shared/globe2.webp");
        background-repeat: no-repeat;
        background-size: contain;
        height: 15px;
        width: 15px;
        margin-left: 2px;
      }
    }
  }
  .social-buttons {
    .action-icon.like {
      background-image: url("/assets/images/icons/social/facebook/like_icon.webp");
    }
    .action-icon.comment {
      background-image: url("/assets/images/icons/social/facebook/comment_icon.webp");
    }
    .action-icon.share {
      background-image: url("/assets/images/icons/social/facebook/share_icon.webp");
    }
    .action-label {
      color: #858889;
    }
  }
}

div.instagram {
  max-width: 320px;
  .platform-icon {
    background-image: url("/assets/images/icons/social/instagram_channel.png");
  }

  .preview-top {
    align-items: center;
    .channel-wrapper {
      align-items: center;
      .profile-avatar {
        width: 32px;
        height: 32px;

        .channel-name {
          height: 100%;
        }
      }
    }
  }

  .preview-bottom {
    & > div:nth-child(1) {
      order: 3;
    }
    & > div:nth-child(2) {
      order: 1;
    }
    & > div:nth-child(3) {
      order: 2;
    }

    .initial-image {
      background-color: #fafafa;
      background-image: url("/assets/images/icons/social/instagram/default.webp");
      background-size: 80%;
      background-repeat: no-repeat;
      background-position: center;
      height: 320px;
      width: 320px;
    }
  }

  .text-preview {
    margin-top: 0.25em;
  }
  .image-preview {
    margin-top: 0.25em;
  }

  .social-buttons {
    justify-content: flex-start;
    margin-top: 0.25em;

    > div:nth-child(4) {
      margin-left: auto;
      .action-icon {
        margin-right: 0;
      }
    }
    .action-icon.like {
      background-image: url("/assets/images/icons/social/instagram/like_icon.webp");
    }
    .action-icon.comment {
      background-image: url("/assets/images/icons/social/instagram/comment_icon.webp");
    }
    .action-icon.send {
      background-image: url("/assets/images/icons/social/instagram/send_icon.webp");
      background-position-y: 2px;
    }
    .action-icon.favorites {
      background-image: url("/assets/images/icons/social/instagram/favorites_icon.webp");
    }
    .action-label {
      display: none;
    }
  }
}

div.twitter {
  max-width: 565px;

  .preview-wrapper {
    padding: 1em;
  }
  .platform-icon {
    background-image: url("/assets/images/icons/social/twitter_channel.png");
  }
  .preview-top {
    .channel-name {
      flex-direction: row;
      align-items: center;

      &::after {
        content: "@profile-name · Just now";
        font-size: 0.85rem;
        margin-left: 0.5em;
      }
    }
    .opts {
      margin-top: 0.5em;
    }
  }

  .preview-bottom {
    .text-preview {
      padding-right: 0;
      padding-left: calc(48px + 1.5em);
      margin-top: -1.5em;
    }
    .image-preview {
      padding-left: calc(48px + 1.5em);
      padding-right: 4em;
    }
  }
  .social-buttons {
    padding-left: calc(48px + 1.5em);
    width: 90%;
    .action-icon {
      background-size: 75%;
    }
    .action-icon.like {
      background-image: url("/assets/images/icons/social/twitter/like_icon.webp");
    }
    .action-icon.comment {
      background-image: url("/assets/images/icons/social/twitter/comment_icon.webp");
    }
    .action-icon.retweet {
      background-image: url("/assets/images/icons/social/twitter/retweet_icon.webp");
      height: 20px;
    }
    .action-icon.share {
      background-image: url("/assets/images/icons/social/twitter/share_icon.webp");
    }
    .action-icon.stats {
      background-image: url("/assets/images/icons/social/twitter/stats_icon.webp");
      height: 20px;
    }
    .action-label {
      color: #525458;
      font-size: 0;

      &::before {
        content: "0";
        font-size: 1rem;
      }
    }
    .action-label.share {
      &::before {
        content: "";
      }
    }
  }
}

div.linkedin {
  max-width: 555px;
  .platform-icon {
    background-image: url("/assets/images/icons/social/linkedin_channel.png");
  }

  .preview-top {
    .opts {
      width: 24px;
      height: 24px;
      background-image: url("/assets/images/icons/social/shared/dots-wide.svg");
      background-size: contain;
    }

    .channel-meta {
      line-height: 1rem;
      &::before {
        content: "X followers \A Just now ·";
        font-size: 0.85rem;
        white-space: break-spaces;
        opacity: 0.85;
      }
      &:after {
        content: "";
        display: inline-block;
        background-image: url("/assets/images/icons/social/shared/globe.svg");
        background-repeat: no-repeat;
        height: 15px;
        width: 15px;
        margin-left: 2px;
      }
    }
  }

  .social-buttons {
    .action-icon.like {
      background-image: url("/assets/images/icons/social/linkedin/like_icon.webp");
    }
    .action-icon.comment {
      background-image: url("/assets/images/icons/social/linkedin/comment_icon.webp");
    }
    .action-icon.repost {
      background-image: url("/assets/images/icons/social/linkedin/repost_icon.webp");
    }
    .action-icon.send {
      background-image: url("/assets/images/icons/social/linkedin/send_icon.webp");
    }
    .action-label {
      color: #85878a;
    }
  }
}
</style>
