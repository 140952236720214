<template>
  <validation-observer
    v-slot="{ invalid, changed }"
    style="display: flex; flex: 1"
  >
    <div class="d-flex d-flex-c d-flex-between" style="flex: 1">
      <div class="d-flex d-flex-r">
        <single-image-upload
          ref="imageUpload"
          style="margin-right: 1em"
          v-model="formData['projectImage']"
          :initialThumbnail="projectImage"
          :folder="uploadContext"
        />
        <div class="d-flex d-flex-c" style="flex: 1">
          <form>
            <component
              v-for="(field, index) in fields"
              :key="field.key || index"
              :is="field.type"
              v-bind="field.props"
              v-model="formData[field.props.name]"
              @trigger="field.trigger"
            ></component>
          </form>
        </div>
      </div>
      <div class="d-flex d-flex-r d-flex-between">
        <content-button
          label="Delete project"
          @button-click="removeProject(projectId)"
          :disabled="isDeleting"
          style="background-color: #ff5d67"
        />
        <content-button
          label="Save"
          :disabled="!changed || invalid || isSaving || uploadRequesting"
          @button-click="submit"
        />
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapState, mapActions } from "vuex";
import { eventBus } from "@/utils/event-bus";
import {
  getZonesByCountry,
  getCountryByTimezone,
  getFormattedTimezone,
  COUNTRIES,
} from "@/utils/timezone";
import {
  ContentInput,
  ContentButton,
  FormSelect,
  TagsWrapper,
} from "@/components/ui/";

import SingleImageUpload from "@/components/ui/forms/SingleImageUpload.vue";

export default {
  components: {
    SingleImageUpload,
    ContentInput,
    ContentButton,
    FormSelect,
    TagsWrapper,
    ValidationObserver,
    MemberAvatars: () =>
      import("@/components/").then((res) => {
        return res.MemberAvatars;
      }),
  },
  props: {
    projectId: { type: [String, Number], default: null },
    projectName: { type: String, default: "" },
    projectImage: { type: String, default: "" },
    timezone: { type: String },
    channels: { type: Array, default: () => [] },
    members: { type: Array, default: () => [] },
  },
  created() {
    const projectCountry = {
      key: "country",
      value: getCountryByTimezone(this.timezone),
    };
    const tz = getFormattedTimezone(projectCountry.value, this.timezone);
    this.formData.country = projectCountry;
    this.formData.tz = tz;
  },
  mounted() {
    eventBus.$on("alter-channels", (data = []) => {
      this.formData.channels = this.allChannels.filter((channel) =>
        data.includes(channel.id.toString()),
      );
    });
  },
  data() {
    return {
      formData: {
        projectImage: this.projectImage,
        projectName: this.projectName,
        channels: this.channels,
        members: this.members,
        country: {},
        tz: {},
      },
      uploadRequesting: false,
    };
  },
  computed: {
    ...mapState({
      isSaving: (state) => state.project.status.requesting.updateProject,
      isDeleting: (state) => state.project.status.requesting.deleteProject,
      allChannels: (state) => state.channel.channels,
      currentTeamMembers: (state) => state.team.currentTeam.members,
      uploadContext: (state) => {
        const teamId = state.team.currentTeam.id || "";
        const sep = teamId ? "/" : "";
        const userId = state.dashboard.user.id;

        return `${userId}${sep}${teamId}`;
      },
    }),
    fields() {
      let scope = this;
      return [
        {
          type: "ContentInput",
          trigger: () => ({}),
          props: {
            name: "projectName",
            label: "Project name",
            rules: "required",
          },
        },
        {
          type: "FormSelect",
          trigger: scope.changeTz,
          props: {
            name: "country",
            label: "Time zone",
            items: COUNTRIES,
            wide: true,
          },
        },
        {
          type: "FormSelect",
          key: scope.formData.country.value,
          trigger: () => ({}),
          props: {
            name: "tz",
            items: getZonesByCountry(scope.formData.country.value),
            wide: true,
          },
        },
        {
          type: "TagsWrapper",
          trigger: () =>
            scope.setActiveModal({
              name: "EditProjectChannelsModal",
              props: { projectChannels: scope.formData.channels },
            }),
          props: {
            name: "channels",
            tags: scope.formData.channels.map((channel) => {
              return {
                id: channel.id,
                label: channel.channel_name,
                type: channel.platform_type,
              };
            }),
            label: "Channels",
            actionLabel: "Add or remove channels",
          },
        },
        {
          type: "MemberAvatars",
          trigger: () =>
            scope.setActiveModal({
              name: "ListModal",
              props: {
                items: scope.currentTeamMembers.map((member) => ({
                  id: member.id,
                  key: member.email,
                  value: member.email,
                  isSelected: scope.formData.members.some(
                    (id) => id === member.id,
                  ),
                })),
                title: "Select members",
                enableAvatar: true,
              },
              modalSubmit: (members) => {
                scope.formData.members = members
                  .filter((member) => member.isSelected)
                  .map((member) => member.id);
              },
            }),
          props: {
            name: "members",
            enableHover: true,
            isInput: true,
            members: scope.currentTeamMembers
              .filter((member) =>
                scope.formData.members.some((m) => m === member.id),
              )
              .map((member) => ({
                id: member.id,
                name: member.name,
                email: member.email,
                avatar: member.avatar,
              })),
            label: "Members",
            actionLabel: "Add or remove members",
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      updateProject: "updateProject",
      deleteProject: "deleteProject",
      setActiveModal: "setActiveModal",
    }),
    changeTz(country) {
      this.formData.tz = getZonesByCountry(country.value)[0];
    },
    removeProject(projectId) {
      this.deleteProject(projectId).then((res) => {
        this.$emit("update-selection");
      });
    },
    async submit() {
      const { projectName, tz, projectImage, channels, members } =
        this.formData;
      const defaultChannelIds = this.channels.map((channel) => channel.id);
      const updatedChannelIds = channels.map((channel) => channel.id);
      const defaultMemberIds = this.members.map((member) => member);
      const updatedMemberIds = members.map((member) => member);

      const payload = {
        id: this.projectId,
        name: projectName,
        timezone: tz.key,
        project_picture: projectImage ? projectImage : "",
        convert_timezone: false,
        add_channels: updatedChannelIds.filter(
          (id) => !defaultChannelIds.includes(id),
        ),
        remove_channels: defaultChannelIds.filter(
          (id) => !updatedChannelIds.includes(id),
        ),
        add_members: updatedMemberIds.filter(
          (id) => !defaultMemberIds.includes(id),
        ),
        remove_members: defaultMemberIds.filter(
          (id) => !updatedMemberIds.includes(id),
        ),
      };

      const imgComponent = this.$refs["imageUpload"];
      if (imgComponent && imgComponent.uploadData) {
        this.uploadRequesting = true;
        const uploadResponse = await imgComponent.save();
        payload.project_picture = uploadResponse;
      }
      this.updateProject(payload).then((updatedProject) => {
        this.$emit("update-selection", updatedProject);
      });
      this.uploadRequesting = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
