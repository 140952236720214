<template>
  <div class="content-display-wrapper">
    <div
      class="picture"
      @click="$emit('display-click')"
      :style="setBg(project_picture)"
    ></div>
    <label class="content-display-label">{{ project_name }}</label>
  </div>
</template>
<script>
export default {
  props: {
    project_picture: { type: String, default: "" },
    project_name: { type: String, default: "" },
  },
  methods: {
    setBg(project_picture) {
      return project_picture && project_picture.length > 0
        ? `background-image: url("${project_picture}")`
        : "background-image: url('/assets/images/layout/dashboard/common/content_bg.svg')";
    },
  },
};
</script>
<style lang="scss" scoped>
.content-display-wrapper {
  margin: 0 0 2em 0;
  flex-basis: 33%;
  cursor: pointer;
  .picture {
    display: block;
    position: relative;
    width: 205px;
    height: 220px;
    margin: 0 auto 0.5em;
    border-radius: 6px;
    background-size: cover;
    background-position: center center;
  }

  .content-display-label {
    display: block;
    width: 205px;
    margin: 0 auto;
    text-align: center;
    overflow-wrap: break-word;
  }

  &:hover {
    .picture {
      opacity: 0.75;
    }
  }
}
</style>
