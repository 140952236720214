<template>
  <div class="d-flex d-flex-c">
    <empty-state v-if="isEmpty" />
    <div v-else class="d-flex d-flex-c mt-2 analytics-wrapper content">
      <div
        class="d-flex align-center time-frame-wrapper"
        @click="toggleRangeSelector"
      >
        Timeframe: <b class="ml-05">{{ intervalLabel }}</b>
      </div>
      <range-selector
        v-if="rangeSelectorToggled"
        :initialStartRange="startRange"
        :initialEndRange="endRange"
        :initialRangeIndex="rangeIndex"
        @apply-range="setRange"
        @close="toggleRangeSelector"
      />
      <div class="d-flex d-flex-c">
        <div
          v-if="hasCategories"
          class="d-flex d-flex-r mb-1 metric-types-wrapper"
        >
          <span
            v-for="(category, index) in metricCategories"
            :key="index"
            :class="['tab', { 'is-selected': selectedTabIndex === index }]"
            @click="() => selectedTab(index)"
            >{{ category }}</span
          >
        </div>
        <component v-bind:is="platformTypeComponent" v-bind="metricsProps" />
      </div>
    </div>
    <full-size-loader
      v-if="isFetching"
      label="Fetching metrics"
      :loaderProps="loaderProps"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import dayjs from "@/utils/timezone";

import EmptyState from "./EmptyState.vue";

const platformComponents = {
  facebook: {
    metricTypes: {
      overview: "FacebookOverview",
      demographics: "FacebookAudience",
    },
  },
  instagram: {
    metricTypes: {
      overview: "InstagramOverview",
      demographics: "InstagramAudience",
    },
  },
  linkedin: {
    metricTypes: {
      overview: "LinkedInOverview",
      demographics: "LinkedInAudience",
    },
  },
  twitter: {
    metricTypes: { overview: "TwitterOverview" },
  },
};

export default {
  name: "Analytics",
  components: {
    EmptyState,
    RangeSelector: () =>
      import("@/components/").then((res) => {
        return res.RangeSelector;
      }),
    FacebookOverview: () => import("./facebook/FacebookOverview.vue"),
    FacebookAudience: () => import("./facebook/FacebookAudience.vue"),
    InstagramOverview: () => import("./instagram/InstagramOverview.vue"),
    InstagramAudience: () => import("./instagram/InstagramAudience.vue"),
    LinkedInOverview: () => import("./linkedin/LinkedInOverview.vue"),
    LinkedInAudience: () => import("./linkedin/LinkedInAudience.vue"),
    TwitterOverview: () => import("./twitter/TwitterOverview.vue"),
    FullSizeLoader: () =>
      import("@/components/").then((res) => res.FullSizeLoader),
  },
  data() {
    return {
      startRange: null,
      endRange: null,
      rangeIndex: 2,
      rangeSelectorToggled: false,
      intervalLabel: "Last 7 days",
      selectedTabIndex: 0,
      loaderProps: {
        width: 25,
        height: 25,
      },
    };
  },
  computed: {
    ...mapState({
      selectedChannel: (state) =>
        state.channel.channels.find(
          (channel) => channel.id === state.analytics.selectedChannelId,
        ) || {},
      metricsProps: (state) => {
        return { metrics: state.analytics.channelMetrics };
      },
      isFetching: (state) =>
        state.analytics.status.requesting.getChannelMetrics,
    }),
    metricCategories() {
      return this.selectedChannel.platform_type
        ? Object.keys(
            platformComponents[this.selectedChannel.platform_type].metricTypes,
          )
        : [];
    },
    hasCategories() {
      return this.metricCategories.length > 0;
    },
    platformTypeComponent() {
      if (this.selectedChannel && this.selectedChannel.platform_type) {
        return platformComponents[this.selectedChannel.platform_type]
          .metricTypes[this.metricCategories[this.selectedTabIndex]];
      } else {
        return "";
      }
    },
    channelId() {
      return this.selectedChannel.id;
    },
    isEmpty() {
      return !this.channelId;
    },
  },
  watch: {
    channelId(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        const payload = {
          channelId: newValue,
          params: {
            start_range: this.startRange.format("YYYY-MM-DD"),
            end_range: this.endRange.format("YYYY-MM-DD"),
          },
        };
        this.getChannelMetrics(payload);
      }
    },
  },
  created() {
    this.endRange = dayjs();
    this.startRange = this.endRange.subtract(1, "week");
  },
  beforeDestroy() {
    this.resetMetrics();
  },
  methods: {
    ...mapActions({
      getChannelMetrics: "getChannelMetrics",
      resetMetrics: "resetMetrics",
    }),
    selectedTab(index) {
      this.selectedTabIndex = index;
    },
    toggleRangeSelector(val) {
      if (val) {
        this.rangeSelectorToggled = val;
      } else {
        this.rangeSelectorToggled = !this.rangeSelectorToggled;
      }
    },
    setRange(data) {
      const frequency = data.timeframeData
        ? data.timeframeData.frequency
        : "custom";

      const payload = {
        channelId: this.channelId,
        params: {
          start_range: data.ranges.startRange.format("YYYY-MM-DD"),
          end_range: data.ranges.endRange.format("YYYY-MM-DD"),
        },
      };

      const label =
        frequency === "custom"
          ? `${data.ranges.startRange.format(
              "DD MMM YYYY",
            )} - ${data.ranges.endRange.format("DD MMM YYYY")}`
          : data.timeframeData.label;

      this.intervalLabel = label;

      this.getChannelMetrics(payload).then((res) => {
        if (data.rangeIndex) {
          this.rangeIndex = data.rangeIndex;
        }
        this.startRange = data.ranges.startRange;
        this.endRange = data.ranges.endRange;
        // this.dashboardInsights = res;
        this.selectedInterval = frequency;
        // this.intervalLabel = label;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.analytics-wrapper {
  position: relative;
  height: 100%;
  background-color: transparent !important;
  .range-selector {
    position: absolute;
    right: 0;
  }
  .time-frame-wrapper {
    position: absolute;
    height: 35px;
    min-width: 200px;
    padding: 0 1em;
    right: 1em;
    top: -75px;
    border-radius: 20px;
    background-color: white;
    cursor: pointer;
    z-index: 1050;

    b {
      font-weight: 500;
    }
  }

  .metric-types-wrapper {
    padding: 1em;
    border-radius: 6px;
    background-color: white;

    .tab {
      position: relative;
      margin-right: 2em;
      padding: 0.75em 1.25em;
      text-transform: capitalize;
      cursor: pointer;
      &.is-selected {
        background-color: #d5daef;
        border-radius: 6px;
      }
    }
  }
}

.loader-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  .loader-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1em;
    background: rgba(0, 0, 0, 0.5);
    width: 200px;
    height: 50px;
    border-radius: 6px;
    font-size: 0.85rem;
    color: #fff;
  }
}
</style>
