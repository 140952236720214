<template>
  <div class="d-flex d-flex-r flex-1 d-flex-between">
    <div class="d-flex d-flex-c flex-1">
      <span class="empty-insights mb-2" />
      <span class="empty-text">
        <p>
          In order to view your team insights, create a team in
          <b>"workspace"</b>
        </p>
        <p>
          and invite members, and add channels and projects to start tracking
          your insights
        </p>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyState",
};
</script>

<style lang="scss" scoped>
.empty-insights {
  width: 100%;
  height: 100%;
  max-height: 400px;
  background-image: url("/assets/images/layout/dashboard/team/insights_empty.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.empty-text {
  text-align: center;
}

.right {
  width: 50%;
  background-color: #f9f9f9;
  padding: 1em;
  border: 6px;
}
</style>
