<template>
  <div class="d-flex d-flex-r asset-preview-wrapper">
    <div class="d-flex d-flex-c">
      <object class="selected-asset mb-1">
        <img
          v-if="selectedAsset && selectedAssetPreview"
          :src="selectedAssetPreview"
          @error="defaultBg"
        />
        <span
          v-if="selectedAsset"
          class="close"
          @click="() => deleteItem(selectedAsset.id)"
          @click.stop
          >×</span
        >
        <span v-if="hasSelectedAsset" class="info">{{
          selectedAsset.asset_type === "image"
            ? formattedMetaInfo.resolution
            : formattedMetaInfo.duration
        }}</span>
      </object>
      <div class="d-flex d-flex-r d-flex-between align-center meta-info">
        <span v-if="hasSelectedAsset">{{ formattedMetaInfo.uploader }}</span>
        <button
          class="edit-button edit ml-1"
          @click="
            setActiveModal({
              name: 'AssetDetailsModal',
              props: {
                assetId: selectedAsset.id,
              },
              modalSubmit: () => ({}),
            })
          "
        >
          Show details
        </button>
      </div>
    </div>
    <div class="d-flex d-flex-r ml-1 assets">
      <object
        :key="index"
        v-for="(asset, index) in assets"
        :class="[
          'asset',
          { 'is-selected': !!selectedAsset && asset.id === selectedAsset.id },
        ]"
        @click="() => selectAsset(index)"
      >
        <img
          v-if="asset.media_url"
          :src="
            asset.asset_type === 'image' ? asset.media_url : asset.thumbnail_url
          "
          @error="defaultBg"
        />
      </object>
    </div>
    <validation-provider mode="aggressive">
      <div class="d-flex d-flex-c hidden-input">
        <input
          :ref="validationRef"
          name="assets-preview-input"
          v-model="validationValue"
        />
      </div>
    </validation-provider>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { ValidationProvider } from "vee-validate";
import { formatDuration } from "@/utils/media";

export default {
  name: "AssetsPreview",
  components: { ValidationProvider },
  props: {
    assets: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedIndex: 0,
      validationRef: "assets-preview-ref",
      defaultAssets: JSON.parse(
        JSON.stringify(this.assets.map((asset) => asset.id)),
      ),
    };
  },
  computed: {
    validationValue() {
      const assetIds = this.assets.map((asset) => asset.id);
      const isDefault =
        this.defaultAssets.length === assetIds.length &&
        this.defaultAssets.length > 0 &&
        this.defaultAssets.every((v) => assetIds.includes(v));
      return isDefault ? "default" : "changed";
    },
    selectedAsset() {
      return this.assets[this.selectedIndex] || {};
    },
    hasSelectedAsset() {
      return Object.keys(this.selectedAsset).length > 0;
    },
    selectedAssetPreview() {
      return this.selectedAsset.asset_type === "image"
        ? this.selectedAsset.media_url
        : this.selectedAsset.thumbnail_url;
    },
    formattedMetaInfo() {
      const resolution = `${this.selectedAsset.media_width} X ${this.selectedAsset.media_height}`;
      const duration = formatDuration(this.selectedAsset.media_duration);
      const uploader = `By: ${this.selectedAsset.upload_by}`;
      return {
        resolution,
        duration,
        uploader,
      };
    },
  },
  mounted() {
    // The input must be focused/blurred so that
    // the changed validation will trigger on value change
    this.$refs[this.validationRef].focus();
    this.$refs[this.validationRef].blur();
  },
  methods: {
    ...mapActions({
      setActiveModal: "setActiveModal",
    }),
    selectAsset(index) {
      this.selectedIndex = index;
    },
    defaultBg(e) {
      e.target.src = "/assets/images/layout/dashboard/common/content_bg.svg";
    },
    deleteItem(id) {
      this.$emit("remove-item", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.asset-preview-wrapper {
  width: 100%;

  .selected-asset {
    position: relative;
    height: 250px;
    width: 100%;
    min-width: 320px;
    max-width: 320px;
    overflow: hidden;
    border-radius: 6px;
    background-image: url("/assets/images/layout/dashboard/common/content_bg.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-color: #d5daef;

    img {
      width: 100%;
      height: 100%;
      border-radius: 6px;
      object-fit: cover;
    }

    .close {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 5px;
      right: 5px;
      background: rgba($color: #000, $alpha: 0.8);
      width: 40px;
      height: 40px;
      border-radius: 100%;
      color: white;
      cursor: pointer;
      font-size: 1.5rem;
    }

    .info {
      position: absolute;
      padding: 0.25em 0.5em;
      bottom: 5px;
      right: 5px;
      background: rgba($color: #000, $alpha: 0.8);
      border-radius: 6px;
      color: white;
      cursor: pointer;
      font-size: 0.85rem;
    }
  }

  .meta-info {
    color: #65676b;

    .edit-button {
      background-color: #e4e8f5;
      padding: 0.5em;
      height: fit-content;
      border: none;
      border-radius: 6px;
      font-size: 0.85rem;
      outline: none;
      color: black;
    }
  }

  .assets {
    flex-wrap: wrap;
    max-height: 250px;
    overflow: auto;
    align-content: flex-start;
    .asset {
      position: relative;
      margin: 0 0.75em 1em 0;
      cursor: pointer;
      border-radius: 6px;
      width: 40px;
      height: 40px;
      background-image: url("/assets/images/layout/dashboard/common/content_bg.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-color: #d5daef;
      &.is-selected {
        &::before {
          content: "";
          position: absolute;
          background-image: url("/assets/images/icons/common/eye_icon.webp");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 75%;
          width: 100%;
          height: 100%;
          z-index: 100;
        }
        &::after {
          content: "";
          position: absolute;
          background-color: #221c55;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          border-radius: 6px;
          opacity: 0.85;
          z-index: 50;
        }
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
        object-fit: cover;
      }
    }
  }
}
</style>
