<template>
  <div class="d-flex d-flex-c d-flex-between image-editor">
    <div v-if="data.isSaving" class="d-flex align-center save-status">
      <span class="mr-05">Saving</span> <spinner :width="18" :height="18" />
    </div>
    <div
      :class="['canvas', { 'filters-toggled': filtersToggled }]"
      @dblclick="dblclick"
    >
      <img
        :ref="imgRef"
        :class="[{ 'is-loading': loadingImage }]"
        :alt="data.name"
        :src="data.url"
        @load="start"
        @loadstart="start"
      />
    </div>
    <div class="d-flex d-flex-r d-flex-center">
      <div class="d-flex d-flex-c mt-1 options-wrapper">
        <div
          class="d-flex d-flex-r d-flex-between slider"
          v-if="selectedFilterOption"
        >
          <div class="filter-info mr-1">
            <p class="name">{{ selectedFilterOption }}</p>
          </div>
          <input
            :ref="sliderRef"
            class="flex-1"
            type="range"
            :value="filterOptions[selectedFilterOption]"
            :min="filterMin"
            :max="filterMax"
            @input="updateFilter"
            @mouseup="addFilterHistory"
            @mousedown="takeFilterSnapshot"
          />
        </div>
        <div
          v-if="filtersToggled"
          class="d-flex d-flex-r d-flex-between align-center filter-toolbar"
          @click="click"
        >
          <button
            :class="[
              'toolbar__button',
              { 'is-selected': selectedFilterOption === 'brightness' },
            ]"
            title="Brightness"
            @click="() => toggleSlider('brightness', 0, 200)"
          >
            <span class="fa fa-certificate" />
          </button>
          <button
            :class="[
              'toolbar__button',
              { 'is-selected': selectedFilterOption === 'contrast' },
            ]"
            title="Contrast"
            @click="() => toggleSlider('contrast', 0, 100)"
          >
            <span class="fa fa-adjust" />
          </button>
          <button
            :class="[
              'toolbar__button',
              { 'is-selected': selectedFilterOption === 'saturation' },
            ]"
            title="Saturation"
            @click="() => toggleSlider('saturation', 0, 200)"
          >
            <span class="fa fa-sun-o" />
          </button>
          <button
            :class="[
              'toolbar__button',
              { 'is-selected': selectedFilterOption === 'inversion' },
            ]"
            title="Inversion"
            @click="() => toggleSlider('inversion', 0, 100)"
          >
            <span class="fa fa-tint" />
          </button>
          <button
            :class="[
              'toolbar__button',
              { 'is-selected': selectedFilterOption === 'blur' },
            ]"
            title="Blur"
            @click="() => toggleSlider('blur', 0, 10)"
          >
            <span class="fa fa-spinner" />
          </button>
          <button
            :class="[
              'toolbar__button',
              { 'is-selected': selectedFilterOption === 'hue' },
            ]"
            title="Hue"
            @click="() => toggleSlider('hue', 0, 360)"
          >
            <span class="fa fa-lightbulb-o" />
          </button>
          <button
            :class="[
              'toolbar__button',
              { 'is-selected': selectedFilterOption === 'grayscale' },
            ]"
            title="Grayscale"
            @click="() => toggleSlider('grayscale', 0, 100)"
          >
            <span class="fa fa-circle" style="margin-right: -3px" />
            <span class="fa fa-circle-thin" style="margin-left: -3px" />
          </button>
          <button
            :class="[
              'toolbar__button',
              { 'is-selected': selectedFilterOption === 'sepia' },
            ]"
            title="Sepia"
            @click="() => toggleSlider('sepia', 0, 100)"
          >
            <span class="fa fa-picture-o" />
          </button>
        </div>

        <!---------- easy seperation -->
        <div
          :class="[
            'd-flex d-flex-r d-flex-between align-center toolbar',
            { 'filters-toggled': filtersToggled },
          ]"
          @click="click"
        >
          <button
            :class="['toolbar__button', { 'is-selected': mode === 'move' }]"
            data-action="move"
            title="Move (M)"
          >
            <span class="fa fa-arrows" />
          </button>
          <button
            :class="['toolbar__button', { 'is-selected': mode === 'crop' }]"
            data-action="crop"
            title="Crop (C)"
          >
            <span class="fa fa-crop" />
          </button>
          <button
            class="toolbar__button"
            data-action="zoom-in"
            title="Zoom In (I)"
          >
            <span class="fa fa-search-plus" />
          </button>
          <button
            class="toolbar__button"
            data-action="zoom-out"
            title="Zoom Out (O)"
          >
            <span class="fa fa-search-minus" />
          </button>
          <button
            class="toolbar__button"
            data-action="rotate-left"
            title="Rotate Left (L)"
          >
            <span class="fa fa-rotate-left" />
          </button>
          <button
            class="toolbar__button"
            data-action="rotate-right"
            title="Rotate Right (R)"
          >
            <span class="fa fa-rotate-right" />
          </button>
          <button
            class="toolbar__button"
            data-action="flip-horizontal"
            title="Flip Horizontal (H)"
          >
            <span class="fa fa-arrows-h" />
          </button>
          <button
            class="toolbar__button"
            data-action="flip-vertical"
            title="Flip Vertical (V)"
          >
            <span class="fa fa-arrows-v" />
          </button>
          <button
            class="toolbar__button"
            title="Filters"
            @click="toggleFilters"
          >
            <span class="fa fa-sliders" />
          </button>
        </div>
      </div>
      <editor-nav :data="data" @change="handleChange" />
    </div>
  </div>
</template>

<script>
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";
import { getFileTypeFromUrl } from "@/utils/url";
import EditorNav from "./EditorNav.vue";
import { Spinner } from "@/components";

const defaultFilterOptions = () => ({
  brightness: 100,
  contrast: 100,
  saturation: 100,
  blur: 0,
  hue: 0,
  inversion: 0,
  sepia: 0,
  grayscale: 0,
});

export default {
  name: "ImageEditor",
  components: { EditorNav, Spinner },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      imgRef: "image",
      imgType: `image/${getFileTypeFromUrl(this.data.url)}`,
      sliderRef: "slider",
      canvasData: null,
      cropBoxData: null,
      croppedData: null,
      cropper: null,
      mode: "move",
      imgLoading: false,
      filtersToggled: false,
      selectedFilterOption: "",
      filterMin: 0,
      filterMax: 100,
      filterOptions: defaultFilterOptions(),
      currentFilterSnapshot: "",
      loadingImage: false,
      editHistory: [],
      historyShifted: false,
    };
  },
  computed: {
    cssFilters() {
      return `brightness(${this.filterOptions.brightness}%)
        contrast(${this.filterOptions.contrast}%)
        saturate(${this.filterOptions.saturation}%)
        invert(${this.filterOptions.inversion}%)
        hue-rotate(${this.filterOptions.hue}deg)
        sepia(${this.filterOptions.sepia}%)
        grayscale(${this.filterOptions.grayscale}%)
        blur(${this.filterOptions.blur}px)
      `;
    },
  },
  mounted() {
    window.addEventListener(
      "keydown",
      (this.onKeydown = this.keydown.bind(this)),
    );
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.onKeydown);
    this.stop();
  },
  methods: {
    click({ target }) {
      const { cropper } = this;
      const action =
        target.getAttribute("data-action") ||
        target.parentElement.getAttribute("data-action");

      switch (action) {
        case "move":
        case "crop":
          if (cropper) {
            cropper.setDragMode(action);
            this.mode = action;
          }
          break;
        case "zoom-in":
          cropper.zoom(0.1);
          break;
        case "zoom-out":
          cropper.zoom(-0.1);
          break;
        case "rotate-left":
          this.addEditHistory({ type: "transform", data: cropper.getData() });
          cropper.rotate(-90);
          if (this.data.pristine) {
            this.update({
              pristine: false,
            });
          }
          break;
        case "rotate-right":
          this.addEditHistory({ type: "transform", data: cropper.getData() });
          cropper.rotate(90);
          if (this.data.pristine) {
            this.update({
              pristine: false,
            });
          }
          break;
        case "flip-horizontal":
          this.addEditHistory({ type: "transform", data: cropper.getData() });
          cropper.scaleX(-cropper.getData().scaleX || -1);
          if (this.data.pristine) {
            this.update({
              pristine: false,
            });
          }
          break;
        case "flip-vertical":
          this.addEditHistory({ type: "transform", data: cropper.getData() });
          cropper.scaleY(-cropper.getData().scaleY || -1);
          if (this.data.pristine) {
            this.update({
              pristine: false,
            });
          }
          break;

        default:
      }
    },
    keydown(e) {
      switch (e.key) {
        // Undo crop
        case "z":
          if (e.ctrlKey) {
            e.preventDefault();
            this.restore();
          }

          break;

        // Delete the image
        case "Delete":
          this.reset();
          break;

        default:
      }

      const { cropper } = this;

      if (!cropper) {
        return;
      }

      switch (e.key) {
        // Crop the image
        case "Enter":
          this.crop();
          break;

        // Clear crop area
        case "Escape":
          this.clear();
          break;

        // Move to the left
        case "ArrowLeft":
          e.preventDefault();
          cropper.move(-1, 0);
          break;

        // Move to the top
        case "ArrowUp":
          e.preventDefault();
          cropper.move(0, -1);
          break;

        // Move to the right
        case "ArrowRight":
          e.preventDefault();
          cropper.move(1, 0);
          break;

        // Move to the bottom
        case "ArrowDown":
          e.preventDefault();
          cropper.move(0, 1);
          break;

        // Enter crop mode
        case "c":
          cropper.setDragMode("crop");
          this.mode = "crop";
          break;

        // Enter move mode
        case "m":
          this.mode = "move";
          break;

        // Zoom in
        case "i":
          cropper.zoom(0.1);
          break;

        // Zoom out
        case "o":
          cropper.zoom(-0.1);
          break;

        // Rotate left
        case "l":
          this.addEditHistory({ type: "transform", data: cropper.getData() });
          cropper.rotate(-90);
          if (this.data.pristine) {
            this.update({
              pristine: false,
            });
          }
          break;

        // Rotate right
        case "r":
          this.addEditHistory({ type: "transform", data: cropper.getData() });
          cropper.rotate(90);
          if (this.data.pristine) {
            this.update({
              pristine: false,
            });
          }
          break;

        // Flip horizontal
        case "h":
          this.addEditHistory({ type: "transform", data: cropper.getData() });
          cropper.scaleX(-cropper.getData().scaleX || -1);
          if (this.data.pristine) {
            this.update({
              pristine: false,
            });
          }
          break;

        // Flip vertical
        case "v":
          this.addEditHistory({ type: "transform", data: cropper.getData() });
          cropper.scaleY(-cropper.getData().scaleY || -1);

          if (this.data.pristine) {
            this.update({
              pristine: false,
            });
          }
          break;

        default:
      }
    },
    dblclick(e) {
      if (e.target.className.indexOf("cropper-face") >= 0) {
        e.preventDefault();
        e.stopPropagation();
        this.crop();
      }
    },
    start() {
      // this.loadingImage = true;
      const { data, mode } = this;

      if (this.cropper) {
        return;
      }

      this.cropper = new Cropper(this.$refs.image, {
        autoCrop: false,
        dragMode: mode,
        background: false,

        ready: () => {
          if (this.croppedData) {
            // this.cropper
            //   .setData(this.croppedData)
            //   .setCanvasData(this.canvasData)
            //   .setCropBoxData(this.cropBoxData);

            this.applyFilter(this.filterOptions);
            this.croppedData = null;
            this.canvasData = null;
            this.cropBoxData = null;
            //this.loadingImage = false;
          }
        },
        crop: ({ detail }) => {
          if (detail.width > 0 && detail.height > 0 && !data.cropping) {
            this.update({
              cropping: true,
            });
          }
        },
      });
    },
    stop() {
      if (this.cropper) {
        this.cropper.destroy();
        this.cropper = null;
      }
    },
    crop() {
      const { cropper, data } = this;

      if (data.cropping) {
        this.croppedData = cropper.getData();
        this.canvasData = cropper.getCanvasData();
        this.cropBoxData = cropper.getCropBoxData();
        this.addEditHistory({
          type: "crop",
          data: data.url,
          croppedData: this.croppedData,
          canvasData: this.canvasData,
          cropBoxData: this.cropBoxData,
        });
        this.update({
          cropped: true,
          cropping: false,
          pristine: false,
          previousUrl: data.url,
          url: cropper
            .getCroppedCanvas(
              data.type === "image/png"
                ? {}
                : {
                    fillColor: "#fff",
                  },
            )
            .toDataURL(data.type),
        });
        this.stop();
      }
    },
    clear() {
      if (this.data.cropping) {
        this.cropper.clear();
        this.update({
          cropping: false,
        });
      }
    },
    restore() {
      if (this.editHistory.length > 0) {
        const { type, data, croppedData, canvasData, cropBoxData } =
          this.editHistory.pop();
        switch (type) {
          case "transform":
            this.cropper.setData(data);
            this.update({
              pristine:
                (this.editHistory.length === 0 && !this.historyShifted) ||
                false,
            });
            break;
          case "filter":
            this.applyFilter(data);
            this.update({
              pristine:
                (this.editHistory.length === 0 && !this.historyShifted) ||
                false,
            });
            break;
          case "crop":
            // this.croppedData = this.cropper.getData();
            this.croppedData = croppedData;
            this.canvasData = canvasData;
            this.cropBoxData = cropBoxData;
            this.update({
              cropped: false,
              pristine:
                (this.editHistory.length === 0 && !this.historyShifted) ||
                false,
              previousUrl: "",
              url: data,
            });
            this.stop();
            break;
        }
      }
    },
    reset() {
      this.stop();
      this.update({
        cropped: false,
        cropping: false,
        loaded: false,
        pristine: true,
        name: "",
        previousUrl: "",
        type: "",
        url: "",
      });
      this.editHistory = [];
      this.historyShifted = false;
      this.filterOptions = defaultFilterOptions();
    },
    update(data) {
      Object.assign(this.data, data);
    },
    save() {
      this.update({ isSaving: true });
      const cropCanvas = this.cropper.getCroppedCanvas();
      const ctx = cropCanvas.getContext("2d");
      ctx.filter = this.cssFilters;
      ctx.drawImage(cropCanvas, 0, 0);
      ctx.canvas.toBlob(
        (blob) => {
          const imgData = {
            file: blob,
            width: cropCanvas.width,
            height: cropCanvas.height,
          };
          this.$emit("init-upload", imgData);
        },
        this.imgType,
        0.75,
      );
    },
    toggleFilters() {
      if (this.filtersToggled) {
        this.selectedFilterOption = "";
      }
      this.filtersToggled = !this.filtersToggled;
    },
    toggleSlider(option, min, max) {
      this.filterMin = min;
      this.filterMax = max;
      this.selectedFilterOption = option;
    },
    applyFilter(filters) {
      const previewImg = document.querySelector(".cropper-container img");
      const cropViewImg = document.querySelector(".cropper-view-box img");
      // const canvasImg = document.querySelector(".canvas > img");
      if (filters) {
        this.filterOptions = filters;
      }
      previewImg.style.filter = this.cssFilters;
      cropViewImg.style.filter = this.cssFilters;
      // canvasImg.style.filters = this.cssFilters;
    },
    takeFilterSnapshot() {
      this.currentFilterSnapshot = { ...this.filterOptions };
    },
    addEditHistory(item) {
      if (this.editHistory.length > 50) {
        this.editHistory.shift();
        this.historyShifted = true;
      }

      this.editHistory.push(item);
    },
    addFilterHistory() {
      if (this.editHistory.length > 50) {
        this.editHistory.shift();
        this.historyShifted = true;
      }
      if (
        Object.values(this.currentFilterSnapshot).sort().toString() !==
        Object.values(this.filterOptions).sort().toString()
      ) {
        this.editHistory.push({
          type: "filter",
          data: this.currentFilterSnapshot,
        });
      }
    },
    updateFilter() {
      const filterSlider = this.$refs[this.sliderRef];
      if (this.data.pristine) {
        this.update({
          pristine: false,
        });
      }
      this.filterOptions[this.selectedFilterOption] = filterSlider.value;
      this.applyFilter();
    },
    handleChange(action) {
      switch (action) {
        case "crop":
          this.crop();
          break;
        case "clear":
          this.clear();
          break;
        case "restore":
          this.restore();
          break;
        case "remove":
          this.reset();
          break;
        case "save":
          this.save();
          break;
        default:
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.image-editor {
  height: calc(100% - 2em);
  max-height: 800px;

  .save-status {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100px;
    border-radius: 6px;
    padding: 0.25em 0.5em;
    color: white;

    .loader {
      height: 20px;
      width: 20px;
    }
  }

  .canvas {
    height: calc(100% - 13em);

    overflow: hidden;

    & > img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  .options-wrapper {
    max-width: 600px;
    max-height: 400px;
    // height: 100%;
    align-self: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    overflow: hidden;
    .slider {
      // position: absolute;
      padding: 1em 1.5em;
      color: #fff;

      .filter-info {
        .name {
          text-transform: capitalize;
        }
      }
    }
    .toolbar,
    .filter-toolbar {
      color: #fff;
      z-index: 50;
      .toolbar__button {
        background-color: transparent;
        border-width: 0;
        color: #fff;
        cursor: pointer;
        font-size: 1rem;
        padding: 1em 1.5em;

        &:focus {
          outline: none;
        }

        &.is-selected,
        &:hover {
          background-color: #aab4db;
          color: #fff;
        }
      }
    }
  }
}
</style>
