<template>
  <validation-observer
    class="d-flex d-flex-c schedule-post-wrapper"
    ref="schedule-post"
    v-slot="{ invalid, changed }"
  >
    <div class="d-flex d-flex-r d-flex align-center mb-3">
      <label class="ml-1 mr-3"
        ><b>{{ label }}</b></label
      >
      <div class="d-flex d-flex-r d-flex-between align-center flex-1">
        <div class="d-flex d-flex-r ml-3">
          <advanced-form-select
            name="projects"
            v-model="selectedProject"
            :items="projects"
            :radio="true"
            @trigger="changeProject"
          />
          <advanced-form-select
            name="channels"
            v-model="payload.channels"
            :items="channels"
            :checkbox="true"
            :checkEnabled="true"
            @checkbox-trigger="alterChannels"
          />
        </div>
        <prev-nav-label :text="prevNavLabel" @click.native="navigateBack" />
      </div>
    </div>
    <div class="d-flex d-flex-r">
      <div class="d-flex d-flex-c flex-1 pr-3">
        <div class="d-flex d-flex-c mb-1 post-container">
          <div class="d-flex d-flex-r d-flex-between align-center mb-1">
            <rounded-switch
              v-model="customizePost"
              label="Customize post per platform"
              @button-click="toggleCustomizePost"
            />
          </div>
          <div class="d-flex d-flex-r mb-05">
            <platform-button
              v-for="(platform, index) in postPlatforms"
              :key="index"
              :platform="platform.name"
              :is-selected="
                selectedPlatform && selectedPlatform.name === platform.name
              "
              class="mr-1"
              @button-click="previewPlatform"
            />
          </div>
          <content-text
            :key="textRenderKey"
            :text="customizedPosts[platformContext]"
            :customizePost="customizePost"
            :is-requesting="contentRequesting"
            :requesting-text="contentRequestingText"
            @on-input-change="setContentText"
            @ai-assitance-click="aiMenuSelect"
          />
        </div>

        <div class="d-flex d-flex-c mb-1 post-container">
          <asset-selection
            :projectId="selectedProject.id"
            :assetIds="payload.assets"
            @select-assets="setAssets"
          />
        </div>

        <div class="d-flex d-flex-c mb-1 post-container">
          <label class="title mb-05">Post Summary</label>
          <schedule-summary :postNow="postNow" :timezone="contentTimeZone" />
        </div>

        <div class="d-flex d-flex-r mb-1 post-container">
          <div class="d-flex d-flex-c flex-1">
            <label class="title mb-05">Scheduled for</label>
            <div class="d-flex d-flex-r d-flex-between">
              <schedule-settings
                :postNow="postNow"
                :timezone="contentTimeZone"
                @toggleScheduleSettings="toggleScheduleSettings"
              />
              <schedule-button
                :optionIndex="scheduleOptionIndex"
                :actionDisabled="invalid || !changed"
                @scheduleContent="handleSubmit"
                @set-post-now-date="setPostNow"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex d-flex-c d-align-end flex-1 pl-3 previews-wrapper">
        <post-preview
          v-if="selectedPlatform"
          :key="selectedPlatform.name"
          :platform="selectedPlatform.name"
          :assetIds="payload.assets"
          :channelName="selectedPlatform.channels[0].channel_name"
          :channelImage="selectedPlatform.channels[0].picture_url"
          :text="customizedPosts[platformContext]"
        />
      </div>
    </div>
    <div class="d-flex d-flex-end">
      <comment-button
        v-if="isDraft"
        @button-click="() => toggleDraftMessages(true)"
      />
    </div>
    <div
      v-if="isDraft && draftMessagesToggled"
      class="d-flex d-flex-c chat-wrapper"
    >
      <div class="d-flex d-flex-r d-flex-between mb-1">
        <label> <b>Drafts messages</b></label>
        <span class="close" @click="() => toggleDraftMessages(false)">×</span>
      </div>
      <draft-chat
        style="height: calc(100% - 4em)"
        :content_id="loadedContent.id"
        :messages="loadedContent.messages"
      />
    </div>
  </validation-observer>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { ValidationObserver } from "vee-validate";
import { platforms } from "@/utils/constants";
import { getCurrentTime, formatDateWithTz } from "@/utils/timezone";
import { RoundedSwitch, CommentButton, AdvancedFormSelect } from "@/components";
import { PlatformButton, PrevNavLabel } from "@/components/dashboard/";
import {
  AssetSelection,
  ScheduleSummary,
  ScheduleButton,
  ScheduleSettings,
} from "@/components/dashboard/content/schedule/";
import ContentText from "@/components/dashboard/content/schedule/ContentText.vue";
import PostPreview from "./PostPreview.vue";

const DATE_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";
const platformKeys = Object.keys(platforms);
const initialData = () => ({
  contentTextRef: "content-text",
  payload: null,
  repeatInfo: {},
  selectedProject: {},
  postNow: false,
  formSubmit: false,
  customizePost: false,
  platforms: platformKeys,
  selectedPlatform: null,
  customizedPosts: {},
});

const requestingText = {
  optimize: "Optimizing text",
  generateHashtags: "Generating hashtags",
  reviseText: "Revizing text",
};

export default {
  name: "Post",
  components: {
    AssetSelection,
    ScheduleSummary,
    ScheduleButton,
    PrevNavLabel,
    ScheduleSettings,
    AdvancedFormSelect,
    ContentText,
    RoundedSwitch,
    PlatformButton,
    PostPreview,
    ValidationObserver,
    CommentButton,
    DraftChat: () =>
      import("@/components/dashboard/content").then((res) => {
        return res.DraftChat;
      }),
  },
  props: {
    label: { type: String, default: "" },
    prevNavLabel: { type: String, default: "" },
  },
  data() {
    return {
      ...initialData(),
      draftMessagesToggled: false,
      textRenderKey: Date.now(),
      contentRequestingText: null,
    };
  },
  computed: {
    ...mapState({
      loadedContent(state) {
        if (this.$route.query.id) {
          return [...state.content.contents, ...state.content.drafts].find(
            (content) => content.id === this.currentContentId,
          );
        } else {
          return null;
        }
      },
      postPlatforms(state) {
        return this.platforms
          .filter((platform) =>
            state.channel.channels
              .filter(
                (channel) =>
                  this.payload &&
                  this.payload.channels &&
                  this.payload.channels.includes(channel.id),
              )
              .map((channel) => channel.platform_type)
              .includes(platform.toLowerCase()),
          )
          .map((platform) => {
            return {
              name: platform,
              channels: state.channel.channels.filter(
                (channel) =>
                  this.payload.channels.includes(channel.id) &&
                  channel.platform_type === platform.toLowerCase(),
              ),
            };
          });
      },
      contentPayload: (state) => state.schedule.contentPayload,
      contentTimeZone(state) {
        return this.selectedProject && this.selectedProject.id
          ? this.projects.find(
              (project) => project.id === this.selectedProject.id,
            ).timezone
          : state.dashboard.user.timezone;
      },
      projects: (state) =>
        [{ id: null, key: 0, value: "None", picture: "", channels: [] }].concat(
          state.project.projects.map((project) => {
            return {
              id: project.id,
              key: project.name,
              value: project.name,
              picture: project.project_picture,
              timezone: project.timezone,
              channels: project.channels,
            };
          }),
        ),
      channels(state) {
        const channelsArray = state.channel.channels
          .filter((channel) => {
            const project =
              state.project.projects.find(
                (p) => p.id === this.selectedProject.id,
              ) || {};
            if (
              this.selectedProject.key === 0 ||
              (project.channels && project.channels.includes(channel.id))
            ) {
              return true;
            }
            return false;
          })
          .map((channel) => {
            return {
              id: channel.id,
              key: channel.channel_name,
              value: channel.channel_name,
              picture: channel.picture_url,
              type: channel.platform_type,
              isSelected: this.payload.channels.includes(channel.id),
            };
          });
        return channelsArray.length > 1
          ? [
              {
                id: "all",
                key: 0,
                value: "All",
                isSelected: channelsArray.every((channel) =>
                  this.payload.channels.includes(channel.id),
                ),
              },
            ].concat(channelsArray)
          : channelsArray;
      },
      isOptimizing: (state) =>
        state.openai.status.requesting.optimizePlatformText,
      isGeneratingHashtags: (state) =>
        state.openai.status.requesting.generateHashtags,
      isRevizing: (state) => state.openai.status.requesting.reviseText,
    }),
    contentRequesting() {
      return this.isOptimizing || this.isGeneratingHashtags || this.isRevizing;
    },

    currentContentId() {
      return this.$route.query.id;
    },
    isDraft() {
      return this.$route.matched.some((match) => match.name === "drafts");
    },
    scheduleOptionIndex() {
      return this.isDraft ? 2 : 0;
    },
    platformContext() {
      return !this.customizePost || !this.selectedPlatform
        ? "initial"
        : this.selectedPlatform.name;
    },
  },
  watch: {
    currentContentId(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.setContentPayload();
      }
    },
    customizePost(newValue) {
      if (newValue === true && Object.keys(this.customizedPosts) === 1) {
        this.customizedPosts = this.platforms.reduce(
          (acc, curr) => ((acc[curr] = this.customizedPosts.initial), acc),
          { initial: this.customizedPosts.initial },
        );
      }
    },
    postPlatforms(newValue, oldValue) {
      if (newValue.length > 0) {
        newValue.forEach((platform, index) => {
          if (!Object.hasOwn(this.customizedPosts, platform.name)) {
            this.customizedPosts[platform.name] = this.customizedPosts.initial;
          }
        });
        if (oldValue.length === 0 && newValue.length > 0) {
          this.selectedPlatform = this.postPlatforms.find(
            (platform) => platform.name === newValue[0].name,
          );
        }
      } else {
        this.selectedPlatform = null;
      }
    },
  },
  created() {
    this.setContentPayload();
  },
  beforeDestroy() {
    this.resetContentPayload();
  },
  methods: {
    ...mapActions({
      saveContent: "saveContent",
      resetContentPayload: "resetContentPayload",
      initContentPayload: "initContentPayload",
      setActiveModal: "setActiveModal",
      removeContentFromList: "removeContentFromList",
      removeDraftFromList: "removeDraftFromList",
      optimizePlatformText: "optimizePlatformText",
      generateHashtags: "generateHashtags",
      reviseText: "reviseText",
      createNotification: "createNotification",
    }),
    setContentPayload() {
      if (this.loadedContent && Object.keys(this.loadedContent).length > 0) {
        this.initContentPayload(this.loadedContent);
      }
      this.payload = JSON.parse(JSON.stringify(this.contentPayload)); // deep copy

      const project =
        this.projects.find(
          (project) => project.id === this.payload.project_id,
        ) || this.projects[0];
      this.selectedProject = {
        id: project.id,
        key: project.key,
        value: project.value,
        picture: project.picture,
        channels: project.channels,
      };
      this.customizedPosts["initial"] = this.payload.text;
    },
    toggleScheduleSettings() {
      this.setActiveModal({
        name: "SelectPostTimeModal",
        props: {
          repeatInfo: {
            repeat_weekdays: this.contentPayload.repeat_weekdays,
            frequency:
              this.contentPayload.frequency || this.contentPayload.repeat_type,
            start_date: this.contentPayload.start_date,
            end_date: this.contentPayload.end_date,
            timezone: this.contentTimeZone,
          },
        },
        modalSubmit: () => ({}),
      });
    },
    changeProject(project) {
      this.selectedProject = project;
      // reset values selection
      this.payload.channels = this.contentPayload.channels.slice();
      this.payload.assets = this.contentPayload.assets.slice();
    },
    alterChannels(channel) {
      if (channel.id === "all") {
        if (channel.value) {
          this.payload.channels = this.payload.channels.concat(
            this.channels.filter((c) => c.id !== "all").map((c) => c.id),
          );
        } else {
          this.payload.channels = [];
        }
      } else {
        if (channel.value) {
          this.payload.channels.push(channel.id);
        } else {
          this.payload.channels = this.payload.channels.filter(
            (c) => c !== channel.id,
          );
        }
      }
    },
    previewPlatform(platformName) {
      this.selectedPlatform = this.postPlatforms.find(
        (platform) => platform.name === platformName,
      );
    },
    setPostNow(value) {
      if (this.postNow !== value) {
        this.postNow = value;
      }
    },
    setContentText(value) {
      if (!this.customizePost) {
        Object.keys(this.customizedPosts).forEach((platform) => {
          this.customizedPosts[platform] = value;
        });
      } else {
        this.customizedPosts[this.platformContext] = value;
      }
    },
    toggleCustomizePost() {
      this.customizePost = !this.customizePost;
    },
    toggleDraftMessages(value) {
      this.draftMessagesToggled = value;
    },
    setAssets(assets) {
      this.payload.assets = assets;
    },
    navigateBack() {
      this.$router.push({
        name: this.$route.matched[this.$route.matched.length - 2].name,
      });
    },
    aiMenuSelect(item) {
      const isCustomizedPost = this.customizePost && this.selectedPlatform;

      if (item.action === "optimize") {
        const isValid =
          (this.customizedPosts.initial &&
            this.customizedPosts.initial.length > 0) ||
          this.customizedPosts[this.selectedPlatform.name].length > 0;
        if (isValid) {
          this.contentRequestingText = requestingText[item.action];
          const payload = {
            message: this.customizedPosts.initial,
            platforms: this.postPlatforms.map((platform) =>
              platform.name.toLowerCase(),
            ),
          };
          this.optimizePlatformText(payload).then((res) => {
            this.setActiveModal({
              name: "OptimizePlatformModal",
              props: {
                optimizedTexts: res,
              },
              modalSubmit: (val) => {
                Object.keys(res).forEach((key) => {
                  const lwcKey = this.platforms.find(
                    (k) => k.toLowerCase() === key,
                  );
                  this.customizedPosts[lwcKey] = res[key];
                });
                this.contentRequestingText = null;
                this.textRenderKey = Date.now();
              },
            });
          });
        }
      } else if (item.action === "generateHashtags") {
        const isValid = isCustomizedPost
          ? this.customizedPosts[this.selectedPlatform.name].length > 0
          : this.customizedPosts.initial.length > 0;

        if (isValid) {
          this.contentRequestingText = requestingText[item.action];
          const payload = {
            message: isCustomizedPost
              ? this.customizedPosts[this.selectedPlatform.name]
              : this.customizedPosts.initial,
          };
          this.generateHashtags(payload).then((res) => {
            this.setActiveModal({
              name: "HashtagsModal",
              props: {
                hashtags: res.hashtags,
              },
              modalSubmit: (val) => {
                let newStr = "";
                if (isCustomizedPost) {
                  newStr =
                    this.customizedPosts[this.selectedPlatform.name].trimEnd();
                  this.customizedPosts[this.selectedPlatform.name] =
                    newStr += ` ${val}`;
                } else {
                  newStr = this.customizedPosts.initial.trimEnd();
                  this.customizedPosts.initial = newStr += ` ${val}`;
                }
                this.textRenderKey = Date.now();
              },
            });
            this.textRenderKey = Date.now();
            this.contentRequestingText = null;
          });
        }
      } else if (item.action === "reviseText") {
        const isValid = isCustomizedPost
          ? this.customizedPosts[this.selectedPlatform.name].length > 0
          : this.customizedPosts.initial.length > 0;

        if (isValid) {
          this.contentRequestingText = requestingText[item.action];
          const payload = {
            message: isCustomizedPost
              ? this.customizedPosts[this.selectedPlatform.name]
              : this.customizedPosts.initial,
          };
          this.reviseText(payload).then((res) => {
            this.setActiveModal({
              name: "TextRevisionModal",
              props: {
                text: payload.message,
                revisedText: res.revision,
              },
              modalSubmit: () => {
                if (isCustomizedPost) {
                  this.customizedPosts[this.selectedPlatform.name] =
                    res.revision;
                } else {
                  this.customizedPosts.initial = res.revision;
                }
                this.textRenderKey = Date.now();
              },
            });
            this.contentRequestingText = null;
          });
        }
      }
    },
    async handleSubmit(option) {
      if (!this.contentTimeZone) {
        const payload = {
          type: "CONTENT_ERROR",
          text: "You must configure your time zone setting before posting",
          status: "error",
        };
        this.createNotification(payload);
        return;
      }
      this.contentPayload.oldStatus = this.contentPayload.status;
      this.contentPayload.status = option.status;
      this.contentPayload.assets = this.payload.assets;
      this.contentPayload.project_id = this.selectedProject
        ? this.selectedProject.id
        : null;

      const requestPayload = {
        ...this.contentPayload,
        start_date:
          option.action === "immediate"
            ? getCurrentTime(this.contentTimeZone).format(DATE_FORMAT)
            : formatDateWithTz(
                this.contentPayload.start_date,
                this.contentTimeZone,
              ).format(DATE_FORMAT),
        end_date:
          option.action === "immediate" &&
          this.contentPayload.repeat_type === "never"
            ? getCurrentTime(this.contentTimeZone)
                .endOf("day")
                .format(DATE_FORMAT)
            : this.contentPayload.end_date
            ? formatDateWithTz(
                this.contentPayload.end_date,
                this.contentTimeZone,
              ).format(DATE_FORMAT)
            : null,
        ...(this.customizePost
          ? {
              customized_content: Object.entries(this.customizedPosts)
                .filter(([key]) => key !== "initial")
                .map(([key, value]) => ({
                  text: value,
                  platform: key.toLowerCase(),
                  channels: this.channels
                    .filter(
                      (channel) =>
                        this.payload.channels.includes(channel.id) &&
                        channel.type === key.toLowerCase(),
                    )
                    .map((channel) => channel.id),
                })),
              text: null,
              channels: null,
            }
          : {
              channels: this.payload.channels,
              text: this.customizedPosts.initial,
            }),
      };

      this.saveContent(requestPayload);
    },
  },
};
</script>
<style lang="scss">
.schedule-post-wrapper {
  .post-container {
    background-color: white;
    border-radius: 6px;
    padding: 1em;

    label.title {
      margin-left: 0.25em;
      font-weight: 600;
      font-size: 1.15rem;
    }
  }

  .previews-wrapper {
    max-height: calc(100vh - (16em - 45px));
    overflow-y: scroll;
  }

  .chat-wrapper {
    position: fixed;
    padding: 2em 1em;
    width: 50%;
    max-width: 650px;
    height: 100vh;
    right: 0;
    top: 0;
    background-color: white;
    z-index: 100;
  }
}
</style>
