<template>
  <div class="media-library-cards-wrapper">
    <media-library-card
      v-for="(card, index) in cards"
      :key="index"
      :cardIndex="index"
      :cardInfo="card"
      :isSelected="index === selectedCardIndex"
      @card-click="() => selectCard(index)"
      @delete-button-click="removeCard"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import MediaLibraryCard from "./MediaLibraryCard.vue";

export default {
  name: "CardsWrapper",
  components: { MediaLibraryCard },
  props: {
    cards: { type: Array, default: () => [] },
  },
  data() {
    return {
      selectedCardIndex: 0,
    };
  },
  created() {
    this.$emit("card-click", this.selectedCardIndex);
  },
  methods: {
    ...mapActions({
      setActiveModal: "setActiveModal",
      saveMediaLibrary: "saveMediaLibrary",
      deleteMediaLibrary: "deleteMediaLibrary",
    }),
    selectCard(index) {
      if (index !== this.selectedCardIndex) {
        this.selectedCardIndex = index;
        this.$emit("card-click", index);
      }
    },
    removeCard(id) {
      this.deleteMediaLibrary({ library_id: id });
    },
  },
};
</script>

<style lang="scss" scoped>
.media-library-cards-wrapper {
  overflow-y: scroll;
  height: 100%;
  padding-right: 1em;

  > div {
    margin-bottom: 2em;
  }
}
</style>
