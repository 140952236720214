<template>
  <validation-observer class="flex-1" v-slot="{ invalid, changed }">
    <div class="d-flex d-flex-c d-flex-between media-library-edit-wrapper">
      <div class="d-flex d-flex-c">
        <view-options
          class="mb-1"
          :options="viewOptions"
          @select-option="selectViewOption"
        />
        <assets-preview
          class="mb-1"
          :assets="assets"
          @remove-item="removeAsset"
        />
        <project-labels
          class="mb-1"
          :projectLabels="projectLabels"
          :boldLabel="true"
          v-model="projectLabels"
        />
        <span
          class="action-label mb-1"
          @click="
            setActiveModal({
              name: 'ListModal',
              props: {
                items: listProjects,
                title: 'Share media library with:',
              },
              modalSubmit: modalSubmit,
            })
          "
          >Add / Remove projects</span
        >
        <content-input
          name="media_library_name"
          label="Media library name"
          v-model="libraryNameModel"
          style="max-width: 250px"
        />
      </div>

      <div class="d-flex d-flex-r d-flex-between">
        <content-button
          label="Upload"
          @button-click="
            setActiveModal({
              name: 'UploadModal',
              props: {
                mediaLibraryId,
              },
              modalSubmit: () => ({}),
            })
          "
        />
        <content-button
          label="Save"
          :disabled="!changed || invalid"
          @button-click="submit"
        />
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { ValidationObserver } from "vee-validate";
import { ViewOptions, ContentInput, ContentButton } from "@/components/ui";
import ProjectLabels from "./ProjectLabels.vue";
import AssetsPreview from "./AssetsPreview.vue";

const options = ["images", "videos"];
export default {
  name: "MediaLibraryEdit",
  components: {
    ViewOptions,
    ContentInput,
    ContentButton,
    ProjectLabels,
    AssetsPreview,
    ValidationObserver,
  },
  props: {
    mediaLibraryId: { type: String, default: null },
    mediaLibraryName: { type: String, default: "" },
    projects: { type: Array, default: () => [] },
  },
  data() {
    return {
      viewOptions: options,
      selectedViewOption: options[0],
      libraryNameModel: this.mediaLibraryName,
      dataProjects: this.projects,
      assetsToDelete: [],
    };
  },
  computed: {
    ...mapState({
      libraryAssets: function (state) {
        return state.asset.assets
          .filter((asset) => asset.asset_library_id === this.mediaLibraryId)
          .filter((asset) => !this.assetsToDelete.includes(asset.id));
      },
      listProjects: function (state) {
        return state.project.projects.map((project) => ({
          id: project.id,
          key: project.name,
          value: project.name,
          isSelected: this.dataProjects.some((p) => p.id === project.id),
        }));
      },
    }),
    assetVideos() {
      return this.libraryAssets.filter((asset) => asset.asset_type === "video");
    },
    assetImages() {
      return this.libraryAssets.filter((asset) => asset.asset_type === "image");
    },
    assets() {
      return this.selectedViewOption === "images"
        ? this.assetImages
        : this.assetVideos;
    },
    projectLabels() {
      return this.dataProjects.map((project) => project.name);
    },
  },
  methods: {
    ...mapActions({
      setActiveModal: "setActiveModal",
      updateMediaLibrary: "updateMediaLibrary",
    }),
    selectViewOption(index) {
      this.selectedViewOption = this.viewOptions[index];
    },
    modalSubmit(items) {
      this.dataProjects = items
        .filter((item) => item.isSelected)
        .map((item) => ({
          id: item.id,
          name: item.value,
          isSelected: item.isSelected,
        }));
    },
    removeAsset(id) {
      this.assetsToDelete.push(id);
    },
    submit() {
      const nameIsChanged = this.mediaLibraryName !== this.libraryNameModel;
      const assetsIsChanged = this.assetsToDelete.length > 0;
      const projectsIsChanged =
        this.dataProjects.length === this.projects.length &&
        this.dataProjects.every((v) => this.projects.includes(v));

      const defaultProjectIds = this.projects.map((project) => project.id);
      const updatedProjectIds = this.dataProjects.map((project) => project.id);

      const projects = {
        add_projects: updatedProjectIds.filter(
          (id) => !defaultProjectIds.includes(id),
        ),
        remove_projects: defaultProjectIds.filter(
          (id) => !updatedProjectIds.includes(id),
        ),
      };

      const data = {
        ...(nameIsChanged && { name: this.libraryNameModel }),
        ...(!projectsIsChanged && {
          ...projects,
        }),
        ...(assetsIsChanged && { remove_assets: this.assetsToDelete }),
      };

      this.updateMediaLibrary({
        data,
        params: { library_id: this.mediaLibraryId },
      }).then((updatedLibrary) => {
        this.$emit("update-selection", updatedLibrary);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.media-library-edit-wrapper {
  height: 100%;
  padding: 1em;
  border-radius: 6px;

  .action-label {
    font-size: 0.75rem;
    padding-top: 0.25em;
    color: #39a4fc;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
