<template>
  <div
    class="d-flex d-flex-c d-flex-between"
    style="height: 100%; flex-direction: column-reverse"
  >
    <chat-text-box @sendMessage="send" :sendingMessage="sendingMessage" />
    <div class="d-flex d-flex-c d-flex-end outer-wrapper">
      <div id="chat-wrapper" class="d-flex d-flex-c draft-chat-wrapper">
        <draft-message
          v-for="(messageData, index) in messages"
          :key="index"
          :message="messageData.message"
          :messageDate="formatDate(messageData.created_at)"
          :messageUserName="messageData.user_name"
          :messageUserAvatar="messageData.user_avatar"
          :style="[
            userId === messageData.user_id && { 'align-self': 'flex-end' },
          ]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "@/utils/timezone";
import { mapState, mapActions } from "vuex";

import DraftMessage from "./DraftMessage";
import ChatTextBox from "./ChatTextBox";

export default {
  name: "DraftChat",
  components: { DraftMessage, ChatTextBox },
  props: {
    content_id: { type: [Number, String] }, //alias for a draft id as well
    messages: { type: Array, default: () => [] },
  },
  computed: {
    ...mapState({
      userId: (state) => state.dashboard.user.id,
      sendingMessage: (state) => state.content.status.requesting.sendMessage,
    }),
  },
  methods: {
    ...mapActions({
      sendMessage: "sendMessage",
    }),
    formatDate(date) {
      return dayjs(date).format("h:mm A DD-MM-YYYY");
    },
    send(message) {
      const payload = {
        content_id: this.content_id,
        user_id: this.userId,
        message,
      };
      this.sendMessage(payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.outer-wrapper {
  background-color: #e4e8f5;
  overflow: hidden;
  height: 100%;
  border-radius: 6px;
  padding-top: 1em;
  padding-right: 0.5em;
  padding-bottom: 1em;

  .draft-chat-wrapper {
    overflow: auto;
    align-items: flex-start;
    flex-direction: column-reverse;
    padding: 0 1em;
  }
}
</style>
