<template>
  <div class="d-flex d-flex-c media-edit-wrapper content">
    <prev-nav-label
      class="media-nav-back"
      :text="prevNavLabel"
      @click.native="navigateBack"
    />
    <div class="d-flex d-flex-r d-flex-between flex-1">
      <div class="image-editor-wrapper">
        <image-editor :data="editorPayload" @init-upload="handleUpload" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ImageEditor from "./ImageEditor.vue";
import { uploadImage } from "@/services/api/asset.api";

import { PrevNavLabel } from "@/components/dashboard/";

export default {
  name: "MediaEdit",
  components: { PrevNavLabel, ImageEditor },
  props: {
    label: { type: String, default: "" },
    prevNavLabel: { type: String, default: "" },
  },
  data() {
    return {
      tuiRef: "toast-ui-element",
      editorPayload: {
        cropped: false,
        cropping: false,
        loaded: false,
        pristine: true,
        isSaving: false,
        name: "",
        previousUrl: "",
        type: "",
        url: "",
      },
    };
  },
  computed: {
    ...mapState({
      asset: function (state) {
        return (
          state.asset.assets.find(
            (asset) => asset.id === this.currentMediaId,
          ) || {}
        );
      },
      uploadContext: (state) => {
        const teamId = state.team.currentTeam.id || "";
        const sep = teamId ? "/" : "";
        const userId = state.dashboard.user.id;

        return `${userId}${sep}${teamId}`;
      },
    }),
    currentMediaId() {
      return this.$route.query.id;
    },
    mediaSrc() {
      return this.asset.media_url;
    },
  },
  created() {
    this.editorPayload.url = this.mediaSrc;
  },
  methods: {
    ...mapActions({
      updateAsset: "updateAsset",
    }),
    navigateBack() {
      this.$router.push({
        name: this.$route.matched[this.$route.matched.length - 2].name,
      });
    },
    async handleUpload(data) {
      this.editorPayload.isSaving = true;
      const { file, width, height } = data;
      const mediaUrl = await uploadImage(file, this.uploadContext);

      const payload = {
        data: {
          media_width: width,
          media_height: height,
          media_size: file.size,
          media_url: mediaUrl,
        },
        params: { asset_id: this.asset.id },
      };
      await this.updateAsset(payload);

      this.editorPayload.isSaving = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.media-edit-wrapper {
  position: relative;
  .media-nav-back {
    position: absolute;
    right: 0;
    top: -57px;
  }

  .image-editor-wrapper {
    position: absolute;
    width: calc(100% - 2em);
    height: 100%;
  }
}
</style>
