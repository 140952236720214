<template>
  <div
    :class="[
      'd-flex d-flex-r d-flex-between team-member-wrapper',
      { 'is-selected': isSelected, 'is-owner': isOwner },
    ]"
    @click="handleClick"
  >
    <div class="d-flex d-flex-r align-center">
      <object class="member-picture mr-1">
        <img v-if="memberInfo.avatar" :src="memberInfo.avatar" />
      </object>
      <div class="d-flex d-flex-c">
        <span class="mb-05"
          ><b>{{ memberInfo.full_name }}</b></span
        >
        <span>{{ memberInfo.email }}</span>
      </div>
    </div>
    <custom-label :label-text="memberRole" />
  </div>
</template>

<script>
import { CustomLabel } from "@/components";

export default {
  name: "TeamMember",
  components: { CustomLabel },
  props: {
    memberInfo: { type: Object, default: () => ({}) },
    memberRole: { type: String, default: "" },
    isOwner: { type: Boolean, default: false },
    isSelected: { type: Boolean, default: false },
  },
  methods: {
    handleClick() {
      this.$emit("on-click", this.memberInfo);
    },
  },
};
</script>

<style lang="scss" scoped>
.team-member-wrapper {
  position: relative;
  min-width: 540px;
  max-width: 685px;
  min-height: 130px;
  padding: 1em;
  background-color: white;
  border: 1px solid #f9f9f9;
  border-radius: 6px;
  cursor: pointer;
  &.is-selected {
    background-color: #d5daef;
  }

  &:hover {
    background-color: #eaefff;
  }

  &.is-selected,
  &:hover {
    .member-role {
      background-color: white;
    }
  }

  .member-picture {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    overflow: hidden;
    background-image: url("/assets/images/icons/dashboard/sidemenu/profile_icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      object-fit: cover;
    }
  }
}
</style>
