<template>
  <div class="d-flex d-flex-c message-container mt-1">
    <div class="d-flex d-flex-r mb-1">
      <span class="message-avatar" :style="setBg(messageUserAvatar)" />
      <span
        ><b>{{ messageUserName }}</b></span
      >
    </div>
    <div class="message-text mb-05">{{ message }}</div>
    <div class="message-date">{{ messageDate }}</div>
  </div>
</template>
<script>
export default {
  name: "DraftMessage",
  props: {
    message: { type: String, default: "" },
    messageDate: { type: String, default: null },
    messageUserName: { type: String, default: "" },
    messageUserAvatar: { type: String, default: "" },
  },
  methods: {
    setBg(background) {
      return background && background.length > 0
        ? `background-image: url("${background}")`
        : "background-image: url('/assets/images/icons/dashboard/sidemenu/profile_icon.svg')";
    },
  },
};
</script>

<style lang="scss" scoped>
.message-container {
  width: fit-content;
  padding: 1em;
  background-color: white;
  border-radius: 6px;
  max-width: 350px;

  .message-avatar {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    margin-right: 1em;
    border-radius: 100%;
  }

  .message-text {
    word-break: break-word;
  }

  .message-date {
    color: #8c8c8c;
    font-size: 0.75rem;
  }
}
</style>
