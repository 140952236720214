<template>
  <div
    class="d-flex d-flex-between d-flex-r align-center pt-1 chat-box-wrapper"
  >
    <emoji-container @insertEmoji="insertEmoji" />
    <textarea
      id="text-area-chat"
      ref="chattextarea"
      v-model="message"
      class="text-container"
      placeholder="Message"
      rows="1"
      v-on:keydown="resize"
    />
    <button
      class="send-message"
      @click="send"
      :disabled="message.length === 0 || sendingMessage"
    ></button>
  </div>
</template>
<script>
import EmojiContainer from "./EmojiContainer.vue";

export default {
  name: "ChatTextBox",
  components: { EmojiContainer },
  props: {
    sendingMessage: { type: Boolean, default: false },
  },
  mounted() {
    this.textAreaScrollHeight = this.$refs.chattextarea.scrollHeight;
  },
  data() {
    return {
      message: "",
      textAreaScrollHeight: 0,
    };
  },
  methods: {
    resize(e) {
      if (!e.shiftKey && e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        this.send();
      } else {
        if (e.target.scrollHeight !== this.textAreaScrollHeight) {
          e.target.style.height = "auto";
          e.target.style.height = e.target.scrollHeight + "px";
        }
      }
    },
    insertEmoji(emoji) {
      const cursorPosition = this.$refs.chattextarea.selectionStart;
      this.message =
        this.message.substring(0, cursorPosition) +
        emoji +
        this.message.substring(cursorPosition);
    },
    send() {
      if (this.message.trim().length > 0) {
        this.$emit("sendMessage", this.message);
        this.message = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-box-wrapper {
  background-color: white;
  .text-container {
    border-radius: 6px;
    padding: 0.45em 0.5em;
    width: 100%;
    margin: 0 1em;
    height: 38px;
    max-height: 90px;
    background-color: #e4e8f5;
    border: none;
    resize: none;
  }

  .send-message {
    display: block;
    width: 20px;
    height: 20px;
    background-image: url("/assets/images/icons/dashboard/draft/message.png");
    background-color: unset;
    background-size: contain;
    background-repeat: no-repeat;
    border: none;

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}
</style>
