<template>
  <div class="d-flex d-flex-r flex-1 d-flex-between">
    <div class="d-flex d-flex-c flex-1">
      <span class="empty-insights mb-2" />
      <span class="empty-text">
        <p style="text-align: center">
          Connect a channel and start analyzing your metrics!
        </p>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyState",
};
</script>

<style lang="scss" scoped>
.empty-insights {
  width: 100%;
  height: 100%;
  min-height: 500px;
  margin-top: 2em;
  // max-height: 400px;
  background-image: url("/assets/images/layout/dashboard/analytics/analytics_empty.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.empty-text {
  text-align: start;
}

.right {
  width: 50%;
  background-color: #f9f9f9;
  padding: 1em;
  border: 6px;
}
</style>
