<template>
  <div class="d-flex d-flex-c mb-2" style="min-width: 100%">
    <type-card
      v-for="(role, index) in roles"
      class="mb-1"
      :key="index"
      :type="role.name"
      :title="role.name"
      :detail="role.description"
      :isSelected="selectedRoleId === role.id"
      :disabled="disabled"
      @type-card-click="() => selectRole(role.id)"
    />
  </div>
</template>

<script>
import { TypeCard } from "@/components/dashboard";
export default {
  name: "TeamRoles",
  components: { TypeCard },
  props: {
    selectedRoleId: { type: String, default: "" },
    roles: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
  },

  methods: {
    selectRole(roleId) {
      this.$emit("update-role", roleId);
    },
  },
};
</script>

<style lang="scss" scoped></style>
