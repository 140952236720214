<template>
  <div :class="['d-flex d-flex-c image-preview-wrapper', platformLowerCase]">
    <div
      :class="['preview-grid', `preview-grid-${cells}`]"
      :style="
        platformLowerCase !== 'instagram' &&
        `padding-bottom:${containerHeight}px`
      "
    >
      <image-item
        v-for="(image, index) in resizedImgs"
        :render="index < displayLimit || platformLowerCase === 'instagram'"
        :key="index"
        :bgImg="image.src"
        :width="image.width"
        :height="image.height"
        :isMore="
          index === cells - 1 && more > 0 && platformLowerCase !== 'instagram'
        "
        :isMoreLength="more"
      />
    </div>
  </div>
</template>

<script>
import { platforms } from "@/utils/constants";
import { getRatioByWidth } from "@/utils/media";
import ImageItem from "./ImageItem.vue";

export default {
  name: "ImagePreview",
  components: { ImageItem },
  props: {
    platform: { type: String, default: "" },
    images: {
      type: Array,
      default: () => [],
    },
    displayLimit: { type: Number, default: 4 },
  },
  async created() {
    this.resizedImgs = await Promise.all(
      this.images.map((image, i) => {
        return getRatioByWidth(
          image.media_url,
          platforms[this.platform].contentWidth,
        );
      }),
    );
  },
  watch: {
    images(newVal, oldValue) {
      Promise.all(
        this.images.map((image, i) => {
          return getRatioByWidth(
            image.media_url,
            platforms[this.platform].contentWidth,
          );
        }),
      ).then((res) => {
        this.resizedImgs = res;
      });
    },
  },
  updated() {
    if (!this.rendered && this.platformLowerCase === "instagram") {
      // references to DOM elements
      const list = document.querySelector(".preview-grid");
      const items = Array.from(document.querySelectorAll(".preview-image"));
      const indicators = Array.from(
        document.querySelectorAll(".preview-indicator"),
      );

      // toggle an `active` class on the indicators
      const activateIndicator = (index) => {
        indicators.forEach((indicator, i) => {
          indicator.classList.toggle("active", i === index);
        });
      };

      // when the observer detects an entry changing
      // (item entering or exiting  list)
      // and the entry is intersecting
      // get the intersecting item’s index
      // set the correct indicator to active
      const onIntersectionObserved = (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            const intersectingIndex = items.indexOf(entry.target);
            activateIndicator(intersectingIndex);
          }
        });
      };

      // create an observer with the list as intersection root
      const observer = new IntersectionObserver(onIntersectionObserved, {
        root: list,
        threshold: 0.6,
      });

      this.observer = observer;

      //observe each item
      items.forEach((item) => {
        observer.observe(item);
      });
      this.rendered = true;
    }
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  data() {
    return {
      resizedImgs: [],
      rendered: false,
      observer: null,
    };
  },
  computed: {
    // grid cells
    cells() {
      return this.images.length > this.displayLimit
        ? this.displayLimit
        : this.images.length;
    },
    // hidden images
    more() {
      return this.images.length - this.cells;
    },
    platformLowerCase() {
      return this.platform.toLowerCase();
    },
    containerWidth() {
      return this.resizedImgs.length * platforms[this.platform].contentWidth;
    },
    containerHeight() {
      const calcHeight =
        this.resizedImgs.reduce((acc, img) => acc + img.height, 0) /
        this.resizedImgs.length;

      const maxHeight = platforms[this.platform].maxContainerHeight;

      return calcHeight < maxHeight ? calcHeight : maxHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
.image-preview-wrapper {
  width: 100%;
  min-height: 300px;
  z-index: 50;

  .preview-grid {
    flex: 1;
    width: 100%;
    height: 100%;
  }

  &.instagram {
    overflow-x: scroll;
    overscroll-behavior: contain;
    scrollbar-width: none;
    touch-action: pan-x;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    .preview-grid,
    .preview-grid-2,
    .preview-grid-3,
    .preview-grid-4,
    .preview-grid-5 {
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      .preview-image {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;
        padding-bottom: 100%;
        border: none;
        background-repeat: no-repeat;
        scroll-snap-align: start;
        scroll-snap-stop: always;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &.linkedin,
  &.facebook {
    .preview-grid-1 {
      height: 100%;
    }
    .preview-grid-1 .preview-image:nth-child(1) {
      width: 100%;
      height: 100%;
      border-right: 0;
    }

    .preview-grid-2 .preview-image,
    .preview-grid-3 .preview-image,
    .preview-grid-4 .preview-image {
      width: 100%;
      height: 50%;
    }

    .preview-grid-3 .preview-image:nth-child(1),
    .preview-grid-4 .preview-image:nth-child(1) {
      height: 60%;
      border-right: 0;
    }

    .preview-image:nth-child(1),
    .preview-image:nth-child(2),
    .preview-image:nth-child(3),
    .preview-image:nth-child(4) {
      height: 50%;
    }

    .preview-grid-3 .preview-image:nth-child(3) {
      border-left: 2px solid white;
    }

    .preview-grid-3 .preview-image:nth-child(2),
    .preview-grid-3 .preview-image:nth-child(3),
    .preview-grid-5 .preview-image:nth-child(1),
    .preview-grid-5 .preview-image:nth-child(2) {
      width: 50%;
    }

    .preview-grid-2 .preview-image:nth-child(2),
    .preview-grid-3 .preview-image:nth-child(2),
    .preview-grid-3 .preview-image:nth-child(3),
    .preview-grid-4 .preview-image:nth-child(2),
    .preview-grid-4 .preview-image:nth-child(3),
    .preview-grid-4 .preview-image:nth-child(4),
    .preview-grid-5 .preview-image:nth-child(3),
    .preview-grid-5 .preview-image:nth-child(4),
    .preview-grid-5 .preview-image:nth-child(5) {
      bottom: 0;
      right: auto;
    }

    .preview-grid-3 .preview-image:nth-child(3),
    .preview-grid-4 .preview-image:nth-child(4),
    .preview-grid-5 .preview-image:nth-child(2),
    .preview-grid-5 .preview-image:nth-child(5) {
      right: 0;
    }

    .preview-grid-3 .preview-image:nth-child(2),
    .preview-grid-3 .preview-image:nth-child(3),
    .preview-grid-4 .preview-image:nth-child(4) {
      border-right: 0;
    }

    .preview-grid-4 .preview-image:nth-child(3),
    .preview-grid-5 .preview-image:nth-child(4) {
      left: 33.333333333333336%;
      top: auto;
      border-right: 2px solid white;
    }

    .preview-grid-3 .preview-image:nth-child(2),
    .preview-grid-3 .preview-image:nth-child(3) {
      height: 40%;
    }

    .preview-grid-4 .preview-image:nth-child(2),
    .preview-grid-4 .preview-image:nth-child(3),
    .preview-grid-4 .preview-image:nth-child(4) {
      height: 40%;
      width: 33.333333333333336%;
    }
  }

  .preview-grid {
    position: relative;
    display: block;
    height: 100%;
  }

  .preview-grid .preview-image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top: solid 2px #fff;
    border-right: solid 2px #fff;

    .close {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 5px;
      right: 5px;
      background: rgba($color: #000000, $alpha: 0.6);
      width: 25px;
      height: 25px;
      border-radius: 100%;
      color: white;
      cursor: pointer;
      font-size: 1.5rem;
    }
  }

  &.twitter {
    .preview-grid {
      border-radius: 24px;
      overflow: hidden;
    }
    .preview-grid-1 .preview-image {
      width: 100%;
      height: 100%;
    }

    .preview-grid-2 .preview-image,
    .preview-grid-3 .preview-image,
    .preview-grid-4 .preview-image,
    .preview-grid-5 .preview-image {
      width: 50%;
    }

    .preview-grid-2 .preview-image,
    .preview-grid-3 .preview-image:nth-child(1),
    .preview-grid-4 .preview-image:nth-child(1) {
      height: 100%;
    }

    .preview-grid-3 .preview-image:nth-child(2),
    .preview-grid-3 .preview-image:nth-child(3),
    .preview-grid-5 .preview-image:nth-child(1),
    .preview-grid-5 .preview-image:nth-child(2) {
      height: 50%;
    }

    .preview-image:last-child,
    .preview-grid-2 .preview-image:nth-child(2),
    .preview-grid-3 .preview-image:nth-child(2),
    .preview-grid-3 .preview-image:nth-child(3),
    .preview-grid-4 .preview-image:nth-child(2),
    .preview-grid-4 .preview-image:nth-child(3),
    .preview-grid-4 .preview-image:nth-child(4),
    .preview-grid-5 .preview-image:nth-child(3),
    .preview-grid-5 .preview-image:nth-child(4),
    .preview-grid-5 .preview-image:nth-child(5) {
      left: auto;
      right: 0;
      border-right: 0;
    }

    .preview-grid-3 .preview-image:nth-child(3),
    .preview-grid-4 .preview-image:nth-child(4),
    .preview-grid-5 .preview-image:nth-child(2),
    .preview-grid-5 .preview-image:nth-child(5) {
      bottom: 0;
      top: auto;
    }

    .preview-grid-4 .preview-image:nth-child(3),
    .preview-grid-5 .preview-image:nth-child(4) {
      top: 33.333333333333336%;
    }

    .preview-grid-4 .preview-image:nth-child(2),
    .preview-grid-4 .preview-image:nth-child(3),
    .preview-grid-4 .preview-image:nth-child(4),
    .preview-grid-5 .preview-image:nth-child(3),
    .preview-grid-5 .preview-image:nth-child(4),
    .preview-grid-5 .preview-image:nth-child(5) {
      height: 33.333333333333336%;
    }
  }
  .loader {
    display: block;
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #9962a9; /* Blue */
    border-radius: 50%;
    width: 45px;
    height: 45px;
    animation: spin 1.5s linear infinite;
  }
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
