<template>
  <div class="d-flex d-flex-r flex-1 d-flex-between">
    <div class="d-flex d-flex-c">
      <span class="empty-projects mb-2" />
      <span class="empty-text">
        <p>You currently have not created any projects. Click on</p>
        <p><b>"Create project"</b> to start managing your projects</p>
      </span>
    </div>
    <div class="d-flex right">
      <span class="empty-project-info" />
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyState",
};
</script>

<style lang="scss" scoped>
.empty-projects {
  width: 420px;
  height: 188px;
  background-image: url("/assets/images/layout/dashboard/projects/projects_empty.svg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 6px;
}

.empty-project-info {
  width: 540px;
  height: 490px;
  background-image: url("/assets/images/layout/dashboard/projects/project_info_empty.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.empty-text {
  text-align: center;
}

.right {
  width: 50%;
  background-color: #f9f9f9;
  padding: 1em;
  border: 6px;
}
</style>
