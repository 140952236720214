<template>
  <div class="d-flex d-flex-r team-wrapper content">
    <empty-state v-if="isEmpty" :context="emptyContext" />
    <div v-else class="d-flex d-flex-r">
      <div class="d-flex d-flex-c content-left">
        <div class="d-flex d-flex-c members-wrapper">
          <team-member
            v-for="(member, index) in members"
            :key="`${index}${member.id}`"
            :member-info="member"
            :member-role="
              (roles.find((role) => role.id === member.role_id) || {}).name
            "
            :is-selected="member.id === selectedMember.id"
            :is-owner="roles.some((role) => role.id === member.role_id)"
            @on-click="selectMember"
          />
        </div>
      </div>
      <div
        :class="[
          'd-flex d-flex-c d-flex-between content-right ml-1',
          { empty: true },
        ]"
      >
        <team-roles
          :roles="filteredRoles"
          :selectedRoleId="selectedMember.role_id"
          :disabled="rolesDisabled"
          @update-role="updateRole"
        />
        <div class="d-flex d-flex-r d-flex-between">
          <content-button
            label="Remove user"
            @button-click="handleRemove"
            style="background-color: #ff5d67"
          />
          <content-button label="Save" @button-click="handleUpdate" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { ContentButton } from "@/components/ui";
import TeamMember from "./TeamMember.vue";
import TeamRoles from "./TeamRoles.vue";
import EmptyState from "./EmptyState.vue";

export default {
  name: "Team",
  components: { TeamMember, TeamRoles, ContentButton, EmptyState },
  data() {
    return {
      selectedMember: {},
    };
  },
  computed: {
    ...mapState({
      members: (state) => state.team.currentTeam.members,
      roles: (state) => state.team.roles.slice().reverse(),
      currentTeam: (state) => state.team.currentTeam,
    }),
    filteredRoles() {
      return this.roles.filter((role) => role.name !== "owner").reverse();
    },
    isEmpty() {
      return !this.currentTeam.id || this.members.length === 0;
    },
    emptyContext() {
      return this.currentTeam.id && this.members.length === 0
        ? "team"
        : "personal";
    },
    rolesDisabled() {
      return (
        this.selectedMember.role_id ===
        (this.roles.find((role) => role.name === "owner") || {}).id
      );
    },
  },
  created() {
    this.selectedMember = { ...this.members[0] };
  },
  methods: {
    ...mapActions({
      updateTeam: "updateTeam",
      updateMember: "updateMember",
      removeMember: "removeMember",
    }),
    selectMember(member) {
      this.selectedMember = { ...member };
    },
    updateRole(roleId) {
      this.selectedMember.role_id = roleId;
    },
    handleRemove() {
      const params = {
        member_id: this.selectedMember.id,
      };
      this.removeMember(params);
    },
    handleUpdate() {
      const payload = {
        data: {
          id: this.selectedMember.id,
          role_id: this.selectedMember.role_id,
        },
      };
      this.updateMember(payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.team-wrapper {
  height: 100%;
  .content-left {
    padding-right: 1em;
    flex: 2 !important;

    .members-wrapper {
      overflow-y: scroll;
      height: 100%;
      padding-right: 1em;
      padding-left: 2px; // prevent dropshadow from being hidden
      padding-top: 2px; // prevent dropshadow from being hidden

      > div {
        margin-bottom: 1em;
      }
    }
  }

  .content-right {
    background-color: #f9f9f9;
    padding: 1em;
    flex: 2 !important;
  }
}
</style>
